import { initializeApp, getApps, getApp, deleteApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import h from "src/h/helpers.js";

const firebaseConfig = {
  apiKey: "AIzaSyDQH3NQg8pW3C8UYls8U9zj-HaR4dOfzgw",
  authDomain:
    process.env.FIREBASE_AUTH_DOMEN_BY_REDIRECT ?? "voccent.firebaseapp.com",
  databaseURL: "https://voccent.firebaseio.com",
  projectId: "voccent",
  storageBucket: "voccent.appspot.com",
  messagingSenderId: "936783312403",
  appId: "1:936783312403:web:27b46ddcfacf3471",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.useDeviceLanguage();

export default ({ store }) => {
  // console.log(
  //   "%cboot/firebase.js (export)",
  //   "background: black; font-weight: bold; color: white;"
  // );

  store.dispatch("setLoading", true);
  store.commit("setError", false);
  onAuthStateChanged(auth, (user) => {
    // console.log("5a5d9e72 onAuthStateChanged", user);
    // console.log("5a5d9e72 onAuthStateChanged (user): ", user);
    // console.log("5a5d9e72 VOC_USER_TOKEN: ", h.getItem("VOC_USER_TOKEN"));
    // console.log("5a5d9e72 VOC_USER_FB_UID: ", h.getItem("VOC_USER_FB_UID"));
    // if (user && !h.getItem('VOC_USER_TOKEN')) {
    if (
      user &&
      (!h.getItem("VOC_USER_FB_UID") ||
        h.getItem("VOC_USER_FB_UID") != user.uid)
    ) {
      // console.log("dispatch");
      store.dispatch("getUserFirebase", { user: user });
    }
    setTimeout(() => {
      store.dispatch("setLoading", false);
      console.log("info-cd552d11: login check");
    }, 2000);
  });
};

<template>
  <div
    class="q-pa-sm column no-wrap bg-0 storycard"
    :class="{
      selectedcard: canchoose === true && selected === true,
      hovercard: canchoose === true && over === true && selected === false,
      story: !(canchoose === true),
      itemcard: canchoose === true,
    }"
    style="
      width: 20rem;
      border-radius: 1rem;
      display: inline-block;
      position: relative;
      overflow: hidden;
    "
    @click.stop="openOrSelectStory(item)"
    @mouseover="overItem()"
    @mouseleave="leaveItem()"
  >
    <!-- image -->
    <div
      class="round-1 bg-2"
      :style="{
        width: '100%',
        position: 'relative',
        border: '1px solid var(--q-2)',
        'background-image': 'url(' + handleStoryPicture() + ')',
        'background-repeat': 'no-repeat',
        'background-position': 'center',
        'background-size': 'contain',
        'aspect-ratio': 1 / 1,
      }"
    >
      <q-icon
        name="theater_comedy"
        class="text-0"
        size="6.9rem"
        style="
          top: 50%;
          left: 50%;
          opacity: 0.5;
          position: absolute;
          transform: translate(-50%, -50%);
        "
      />
      <div
        class="row items-center text-0 q-pt-md q-pb-sm q-pl-sm"
        style="position: absolute; bottom: 0; left: 0"
      >
        <!-- channel avatar -->
        <q-avatar
          style="
            width: 3rem;
            height: 3rem;
            overflow: hidden;
            border: 1px solid var(--q-2);
          "
          class="round-1 bg-5"
          size="3rem"
          color="1"
        >
          <pickerimage
            v-if="item && item.ID"
            :data="{
              uid: 'channel_img_' + item.ID,
              objectid: item.ChannelID,
              objecttype: 'channel_avatar',
            }"
            :view="{
              width: '3rem',
              onlyshow: true,
            }"
            class="bg-transparent full-width full-height"
          />
        </q-avatar>
        <div class="col column no-wrap text-white q-pl-sm">
          <div
            class="row"
            style="
              font-size: 1.3rem;
              font-weight: bold;
              line-height: 1.5rem;
              white-space: nowrap;
              text-shadow:
                0 0 0.25rem #0008,
                0 0 0.5rem #0008;
            "
          >
            {{ item.Name?.substring(0, 20) }}
            {{ item.Name.length > 20 ? "..." : "" }}
          </div>
          <div
            style="
              white-space: nowrap;
              text-shadow:
                0 0 0.25rem #0008,
                0 0 0.5rem #0008;
            "
          >
            {{ item.ChannelName?.substring(0, 30) }}
            &middot;
            {{ item?.UserName }}
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div
      class="column no-wrap text-9 q-px-sm"
      style="position: relative; min-height: 3rem"
    >
      <!-- purple story icon top right -->
      <q-icon
        name="theater_comedy"
        class="text-v"
        size="2rem"
        style="position: absolute; top: 0.5rem; right: 0.5rem"
      />
      <!-- purple public icon top right -->
      <q-icon
        color="v"
        size="1rem"
        class="round-both q-pa-xs bg-0"
        style="position: absolute; top: 2rem; right: -0.25rem"
        :name="
          item.Privacy === 'public'
            ? 'public'
            : item.Privacy === 'unlisted'
              ? 'linkk' //adding a letter at the beginning (like llink) moves the icon to the right. Same with adding at the end
              : 'public_off'
        "
      >
        <q-tooltip
          anchor="top left"
          self="bottom left"
          class="bg-0 text-8 round-both"
          style="border: 1px solid var(--q-3)"
          transition-show="jump-up"
          transition-hide="jump-down"
        >
          <b>{{
            item.Privacy === "public"
              ? $t("-raw-story-privacy-public")
              : item.Privacy === "unlisted"
                ? $t("-raw-story-privacy-unlisted")
                : $t("-raw-story-privacy-private")
          }}</b>
        </q-tooltip>
      </q-icon>
      <!-- rating stars bottom right -->
      <div
        class="bg-0 q-px-xs"
        style="
          position: absolute;
          right: 0.25rem;
          bottom: 0.25rem;
          border-radius: 0.75rem;
          font-weight: bold;
        "
      >
        <div v-if="item.Rating">
          <q-icon name="star" />
          {{ item.Rating.toFixed(1)
          }}<span style="font-size: 0.6rem">/{{ item.CountRating }}</span>
          <q-tooltip
            anchor="top right"
            self="bottom right"
            class="bg-0 text-8 round-both"
            style="border: 1px solid var(--q-3)"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b
              >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
              {{ $t("-raw-challenge-rated-by-users") }}</b
            >
          </q-tooltip>
        </div>
      </div>
      <div class="text-4 q-my-sm" style="white-space: nowrap">
        <!-- <span style="font-weight: bold">{{ $t("-raw-challenge-created") }}: </span> -->
        {{ handleDate(item.createdat) }}
      </div>
      <div
        v-if="
          item.LanguageIDs &&
          Array.isArray(item.LanguageIDs) &&
          item.LanguageIDs.length > 0
        "
      >
        <span style="font-weight: bold"
          >{{
            $t("-raw-languages") === "-raw-languages"
              ? "Languages"
              : $t("-raw-languages")
          }}:
        </span>
        <template
          v-for="(lng1, n1) in item.LanguageIDs"
          :key="n1 + 'story_languages_list1'"
        >
          <template
            v-for="(lng2, n2) in $store.getters.languages"
            :key="n2 + 'story_languages_list2'"
          >
            <template v-if="lng1 === lng2.ID"> {{ lng2.name }}; </template>
          </template>
        </template>
        <div
          style="white-space: nowrap"
          v-if="item.Levels !== null && item.Levels !== undefined"
        >
          <span style="font-weight: bold">
            {{ $t("-raw-story-length") }}:
          </span>
          <span>
            {{ item.Levels }}
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-story-logical-levels") }}</b>
            </q-tooltip>
          </span>
          <span>
            ({{ item.ActItems }})
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b>{{ $t("-raw-story-total-elements") }}</b>
            </q-tooltip>
          </span>
        </div>
      </div>
    </div>

    <div
      style="position: absolute; bottom: 0.5rem; right: 0.5rem"
      class="q-gutter-x-md"
    >
      <q-btn
        @click.stop="$store.dispatch('getChannels'), (dialogCopy = true)"
        v-if="item.Access?.update || isSystem"
        :loading="loading"
        size="0.5rem"
        round
        flat
        color="9"
        class="bg-2 q-my-auto"
        icon="content_copy"
        style="width: 1rem; height: 1rem; position: relative"
      >
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
          class="bg-2 text-8 round-both"
          transition-show="jump-up"
          transition-hide="jump-down"
        >
          <b>{{ $t("-raw-storycard-copy") }}</b>
        </q-tooltip>
      </q-btn>

      <q-dialog v-model="dialogCopy">
        <q-card style="min-width: 350px">
          <q-card-section>
            <div class="text-h6">
              {{ $t("-raw-storycard-select-channel") }}
            </div>
          </q-card-section>
          <q-card-section class="q-pt-none row q-gutter-md">
            <!-- :options="
                $store.state.channels.channels.filter(
                  (c) => c.ID !== $store.getters.currentchannel.ID,
                )
              " -->
            <mychannels
              @select="
                (c) => {
                  channelCopyTo = c;
                }
              "
              :selected="channelCopyTo"
              :options="$store.state.channels.channels"
            />
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn
              flat
              :label="$t('-raw-general-cancel')"
              :loading="loading"
              v-close-popup
            />
            <q-btn
              flat
              :label="$t('-raw-general-copy')"
              v-if="channelCopyTo"
              :loading="loading"
              @click="copyToChannel(item.ID, channelCopyTo.ID)"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-btn
        @click.stop="deleteStory"
        v-if="showdelete === true && (item.Access?.update || isSystem)"
        :loading="loading"
        size="0.5rem"
        round
        flat
        color="n"
        class="bg-2 q-my-auto"
        icon="delete"
        style="width: 1rem; height: 1rem; position: relative"
      >
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
          class="bg-2 text-8 round-both"
          transition-show="jump-up"
          transition-hide="jump-down"
        >
          <b>{{ $t("-raw-storycard-delete") }}</b>
        </q-tooltip>
      </q-btn>
      <q-btn
        @click.stop="editStory"
        v-if="item.Access?.update || isSystem || item.Access?.view"
        :loading="loading"
        size="0.5rem"
        round
        flat
        color="v"
        class="bg-2 q-my-auto"
        icon="edit"
        style="width: 1rem; height: 1rem; position: relative"
      >
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
          class="bg-2 text-8 round-both"
          transition-show="jump-up"
          transition-hide="jump-down"
        >
          <b>{{ $t("-raw-storycard-edit") }}</b>
        </q-tooltip>
      </q-btn>
    </div>

    <q-dialog v-model="alertWarning" @hide="openCopiedStory">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ $t("-raw-storycard-warning-copy") }}</div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            :label="$t('-raw-general-confirm')"
            color="v"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- <div class="row justify-center">
      <div @click.stop="">
        <q-btn-dropdown
          size="md"
          split
          color="v"
          rounded
          flat
          transition-show="jump-right"
          transition-hide="jump-left"
          v-if="item.CurrentPass?.Link"
          :ref="'dropdown_select_' + this.item.ID"
          :loading="loading"
          class="bg-2 q-my-auto"
          style="position: relative"
          no-caps
          dropdown-icon="more_vert"
          @click="
            openStoryPass({ StoryID: item.ID, Link: item.CurrentPass?.Link })
          "
          @show="choosePassDialog = true"
        >
          <template #label>
            {{ $t("-raw-storycard-continue") }}
            <q-icon name="double_arrow"> </q-icon>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-2 text-8 round-both text-bold text-caption"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              {{ $t("-raw-storycard-continue-last-playthrough") }}
            </q-tooltip>
          </template>
        </q-btn-dropdown>
      </div>
    </div> -->

    <!-- <q-dialog
      v-model="choosePassDialog"
      :maximized="choosePassDialogMax"
      persistent
      transition-show="slide-up"
      transition-hide="slide-down"
      style="z-index: 99999 !important"
    >
      <q-card>
        <q-card-section
          :style="{
            height: `calc(${window.innerHeight}px - 5rem) !important`,
          }"
        >
          <q-btn
            text-color="0"
            class="bg-n"
            v-close-popup
            @click="this.$refs['dropdown_select_' + this.item.ID]?.hide()"
            icon="close"
            style="
              position: absolute;
              right: 1rem;
              width: 2rem;
              height: 2rem;
              z-index: 99999 !important;
            "
            no-caps
          ></q-btn>
          <StoryPass
            :data="{ pstoryid: this.item.ID }"
            @choose="chooseStoryPass"
            class="fit"
          />
        </q-card-section>
      </q-card>
    </q-dialog> -->
  </div>
</template>

<style scoped>
.storycard {
  transition: all 0.2s ease;
  border: 1px solid var(--q-2);
}
.storycard:hover {
  /* scale: 1.0125; */
  box-shadow: 0 0 2rem -0.5rem #0004;
}
.storycard:active {
  transition: all 0.075s ease;
  /* scale: 0.975; */
}
.story {
  cursor: pointer;
  border: 2px solid var(--q-1);
  user-select: none;
}
.story:hover {
  border: 2px dashed var(--q-v);
}
.storyBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-size: cover;
  filter: grayscale(100%) brightness(10%);
  opacity: 0.1;
}

.itemcard {
  cursor: pointer;
  border: 2px solid var(--q-1);
  user-select: none;
}
.hovercard {
  border: 2px dashed var(--q-v);
}
.selectedcard {
  border: 2px solid var(--q-p);
}
</style>

<script>
import api from "src/h/api.js";
import Lib from "src/h/helpers.js";
import mychannels from "src/components/mychannels.vue";
import pickerimage from "src/components/picker-picture/picker.vue";
// import StoryPass from "src/pages/story-pass-user/index.vue";

export default {
  // need 'showdelete'=true because need create operation after deleted story from list (reaction for emit 'deleteStory')
  props: ["item", "showdelete", "canchoose"],
  components: {
    mychannels,
    // StoryPass,
    pickerimage,
  },
  data: () => ({
    loading: false,
    permission: null,
    theme: null,
    dialogCopy: null,
    channelCopyTo: null,
    alertWarning: null,
    copyID: null,
    selected: false,
    over: false,
    // choosePassDialog: false,
    // choosePassDialogMax: true,
  }),
  mounted() {
    if (this.item.Access) {
      let permission = {};
      Lib.CopyObject(this.item.Access, permission);
      this.permission = permission;
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isSystem() {
      if (Lib.getItem("VOC_USER_ROLE_IS_SYSTEM")) {
        return true;
      }
      return false;
    },
  },
  methods: {
    handleStoryPicture() {
      if (!this.item.ID) {
        return "";
      }
      return (
        process.env.PUBLIC_API_URL +
        "/api/v1/asset/object/story_picture/" +
        this.item.ID
      );
    },
    editStory() {
      this.loading = true;
      this.getPermission(this.item.ID)
        .then((r) => {
          if (r?.update || r?.view) {
            this.$router.push("/story-studio/" + this.item.ID);
          } else {
            this.$q.notify({
              type: "negative",
              message: "You don't have enough permissions to edit the story",
              classes: "round-both q-mb-sm no-shadow ubuntu",
            });
          }

          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    deleteStory() {
      let pnt = this;
      this.getPermission(this.item.ID)
        .then((r) => {
          if (r?.update) {
            api.CallConfirmation(
              {
                method: "delete",
                message: this.$t("-raw-confirm-delete-story"),
                buttonOk: this.$t("-raw-tooltip-delete"),
              },
              () => {
                return new Promise((resolve, reject) => {
                  pnt.loading = true;
                  api
                    .Call({
                      url: "/api/v1/story/" + pnt.item.ID,
                      method: "delete",
                      show_error: true,
                    })
                    .then(
                      (response) => {
                        pnt.loading = false;
                        this.$emit(
                          "deleteStory",
                          pnt.item.ID,
                          pnt.item.LanguageIDs,
                        );
                      },
                      (e) => {
                        pnt.loading = false;
                      },
                    );
                  resolve();
                });
              },
            );
          } else {
            this.$q.notify({
              type: "negative",
              message: "You don't have enough permissions to delete the story",
              classes: "round-both q-mb-sm no-shadow ubuntu",
            });
          }
        })
        .catch((e) => {});
    },
    getPermission(storyID) {
      if (this.permission) {
        const permission = this.permission;
        return new Promise((resolve, reject) => {
          resolve(permission);
        });
      }

      return api
        .Call({
          url: "/api/v1/share/access/story/" + storyID,
          method: "get",
        })
        .then(
          (response) => {
            this.permission = response;
            return response;
          },
          (e) => {
            this.$store.dispatch("setError", e.message);
            throw e;
          },
        );
    },
    // chooseStoryPass(data) {
    //   this.openStoryPass({ StoryID: this.item.ID, Link: data });
    // },
    handleDate(datesrc) {
      const date = new Date(datesrc);
      return date.toDateString();
    },
    // handleChannelAvatar(channelID) {
    //   return channelID
    //     ? process.env.PUBLIC_API_URL + "/api/v1/asset/object/channel_avatar/" + channelID
    //     : "Cc.svg";
    // },
    copyToChannel(storyID, channelID) {
      this.loading = true;
      api
        .Call({
          url: "/api/v1/story/clone",
          method: "post",
          data: {
            ChannelID: channelID,
            StoryID: storyID,
          },
          // show_error: true,
        })
        .then(
          (story) => {
            this.loading = false;
            this.dialogCopy = false;
            this.copyID = story.ID;
            if (story.Error) {
              this.alertWarning = true;
            } else {
              this.openCopiedStory();
            }
          },
          (e) => {
            this.loading = false;
            console.error(e);
          },
        );
    },
    openCopiedStory() {
      this.$router.push("/story-studio/" + this.copyID);
    },
    openOrSelectStory(story) {
      if (this.canchoose) {
        this.selectItem();
      } else {
        this.openStory(story);
      }
    },
    openStory(story) {
      if (story?.CurrentPass?.Link) {
        // open last story-session
        this.$router.push(
          "/story-pass/" + story.ID + "?l=" + story?.CurrentPass?.Link,
        );
      } else {
        // start new story-session
        this.$router.push("/story-pass/" + story.ID);
      }
    },
    selectItem() {
      this.selected = true;
      this.$emit("choose", this.item.ID);
    },
    unselectItem() {
      this.selected = false;
    },
    overItem() {
      this.over = true;
    },
    leaveItem() {
      this.over = false;
    },
  },
};
</script>

import api from "src/h/api.js";
import { v4 as uuidv4 } from "uuid";
import { Notify } from "quasar";
import { i18n } from "src/boot/i18n.js";
import streamotionAnalysis from "src/components/emotion/streamotion.vue";
import Lib from "src/h/helpers.js";
import { toBlobURL } from "@ffmpeg/util";
import { shallowRef } from "vue";

const API_KEY = process.env.GOOGLE_MAP_PUBLIC_KEY;

const DEVICE_STATUS_INACTIVE = 0; // there's no events from device in eventsList
const DEVICE_STATUS_OFFLINE = 1; // there's at least one event from device in eventsList
const DEVICE_STATUS_ONLINE = 2; // device is sending data now

/** @typedef {Object} DeviceUpdateRequest
 * @property {string} ID
 * @property {string} Name
 * @property {number} AddressLat
 * @property {number} AddressLon
 */
/** @property {DeviceUpdateRequest} emptyEditDevice */
const emptyEditDevice = {
  ID: null,
  Name: null,
  AddressLat: null,
  AddressLon: null,
};

export default {
  props: ["stream_code"],
  components: {
    streamotionAnalysis,
  },
  data: () => ({
    uid: uuidv4(),
    sessionID: uuidv4(),
    loading: true,
    creating: false,
    streamotionToken: null,
    newStreamDialog: false,
    newStream: {
      Code: null,
    },
    jsBlobURL: null,
    wasmBlobURL: null,
    currentStream: {
      ID: null,
      Link: null,
      TTS: false /**
       * @typedef {Object} ArousalValencePoint
       * @property {number} Arousal
       * @property {number} Valence
       */,

      /**
       * @typedef {Object} Device
       * @property {number} ID
       * @property {string} IMEI
       * @property {number} VBat
       * @property {string} MediaType
       * @property {string} Name
       * @property {DEVICE_STATUS_INACTIVE | DEVICE_STATUS_OFFLINE | DEVICE_STATUS_ONLINE} Status
       * @property {string} BatteryIcon
       * @property {string} BatteryColor
       * @property {string} SessionID
       * @property {string} SessionID2
       * @property {number} Arousal
       * @property {number} Valence
       * @property {string} EmotionColor
       * @property {number} GunShot
       * @property {number} GlassBreak
       * @property {number} GPSTimestamp
       * @property {number} Latitude
       * @property {number} Longitude
       * @property {number} Altitude
       * @property {number} Accuracy
       * @property {number} Time
       * @property {Date} LastAnswer
       * @property {number} Num
       * @property {ArousalValencePoint[]} GraphData // it's data for draw in chart (only first value from array, other add inside by method)
       */

      /** @type {Record<string, Device>} */
      Devices: {},
    }, // currentStream.Devices = devicesMap
    // drawDataMap: new Map(), // it's data for draw in chart (only first value from array, other add inside by method)
    markersMap: null, // google maps markers map for existing devices, online and offline only, shallowRef(map());
    paramsURL: {},
    startFrom: null,
    startFromKey: 0,
    drawIntervalMs: 1000, // interval when draw next block in diagram
    drawCountSteps: 50, // how many blocks in diagram draw
    timerPauseInterval: null,
    containerEventsHeight: 200,
    containerDevicesTop: 0,
    /**
     * @typedef {ArousalValencePoint & Object} EventLog
     * @property {string} SessionID
     * @property {string} device
     * @property {string} date
     * @property {string} tooltip
     * @property {string} color
     * @property {string} FileID
     */

    /** @type {EventLog[]} eventsList */
    eventsList: [],
    maxCountEvents: 100, // how many events save and show in scroll
    analytics: null,
    map: null,
    linkIsCopied: null,
    filterDeviceID: null,
    voiceAnalytics: false,
    audioAnalytics: null,
    audioEvent: null,
    audioBlobURL: null,
    autoPlayDeviceID: null,
    showInactiveDevices: false,
    /** @typedef {Object} DeviceUpdateRequest
     * @property {string} ID
     * @property {string} Name
     * @property {string} Description
     * @property {string} StreetAddress
     * @property {number} AddressLat
     * @property {number} AddressLon
     */
    /** @property {DeviceUpdateRequest} editDevice */
    editDevice: Object.assign({}, emptyEditDevice),
    editDeviceMarker: null, // shallowRef(google maps marker),
    editDeviceListener: null, // google maps event listener for editDeviceMarker
    DEVICE_STATUS_INACTIVE: DEVICE_STATUS_INACTIVE,
    DEVICE_STATUS_OFFLINE: DEVICE_STATUS_OFFLINE,
    DEVICE_STATUS_ONLINE: DEVICE_STATUS_ONLINE,
  }),
  watch: {
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          this.load();
        }
      },
      immediate: false,
    },
    newStreamDialog: {
      handler(newVal, oldVal) {
        if (newVal === true) {
          this.newStream.Code = null;
          setTimeout(() => {
            if (this.$refs["qDialogCode_" + this.uid]) {
              this.$refs["qDialogCode_" + this.uid].focus();
            }
          }, 100);
        }
      },
      immediate: false,
    },
  },
  mounted() {
    let theme = this.$store.getters.getDefaultTheme;
    if (theme) this.$store.dispatch("setTheme", theme);
    if (!API_KEY) {
      console.error("GOOGLE_MAP_PUBLIC_KEY is not defined.");
    }
    this.jsBlobURL = toBlobURL(`ffmpeg-core.js`, "text/javascript");
    this.wasmBlobURL = toBlobURL(`ffmpeg-core.wasm`, "application/wasm");
    this.markersMap = shallowRef({});
    this.markersMap.value = new Map();
    this.load();
  },
  beforeUnmount() {
    if (this.timerPauseInterval) {
      clearInterval(this.timerPauseInterval);
    }
    if (this.jsBlobURL) {
      URL.revokeObjectURL(this.jsBlobURL);
    }
    if (this.wasmBlobURL) {
      URL.revokeObjectURL(this.wasmBlobURL);
    }
    this.streamotionDisconnect(200);
  },
  methods: {
    load() {
      // clear - actual for watch ws status
      this.creating = false;
      this.loading = true;
      this.currentStream = {};
      this.currentStream.Devices = {};
      this.newStream.Code = null;
      this.newStreamDialog = false;
      this.stopAllAudio();

      this.parseParamsUrl();
      if (this.$route.params.link) {
        this.getStreamByLink(this.$route.params.link)
          .then(this.setStream)
          .catch((err) => {
            console.error(err);
            this.$q.notify({
              type: "negative",
              message: i18n.global.t("-raw-error-occurred"),
            });
            this.loading = false;
          });
      } else if (
        (this.stream_code !== null && this.stream_code != undefined) ||
        this.paramsURL["code"]
      ) {
        if (this.stream_code !== null && this.stream_code != undefined) {
          this.setParamsUrl({ code: this.stream_code });
        }
        this.getStreamByCode(this.paramsURL["code"])
          .then(this.setStream)
          .catch((err) => {
            console.error(err);
            if (err.code === "undefined_code") {
              this.setParamsUrl({ code: "" });
              this.loading = false;
              // setTimeout(() => {
              //   this.newStreamDialog = true;
              // }, 200);
            } else {
              this.$q.notify({
                type: "negative",
                message: i18n.global.t("-raw-error-occurred"),
              });
              this.loading = false;
            }
          });
      } else {
        this.loading = false;
        // setTimeout(() => {
        //   this.newStreamDialog = true;
        // }, 200);
      }
    },
    /** @param {Device} data */
    addOrUpdateDevice(data) {
      // override device data, but keep old data, if exists
      this.currentStream.Devices[data.ID] = {
        ...this.currentStream.Devices[data.ID],
        ...data,
      };
      const device = this.currentStream.Devices[data.ID];
      device.Status = device.Status || DEVICE_STATUS_INACTIVE;
      device.Arousal = device.Arousal || 0;
      device.Valence = device.Valence || 0;
      const pnt = Lib.calculateEmotionPoint({
        Arousal: device.Arousal,
        Valence: device.Valence,
      });
      device.EmotionColor = pnt.color;
      device.Latitude = device.AddressLat || device.Latitude;
      device.Longitude = device.AddressLon || device.Longitude;
      const batteryLevel = this.batteryLevel(device.VBat);
      device.BatteryIcon = device.BatteryIcon || batteryLevel?.icon;
      device.BatteryColor = device.BatteryColor || batteryLevel?.color;
      if (!device.GraphData) {
        device.GraphData = Array(this.drawCountSteps).fill({
          Arousal: 0,
          Valence: 0,
        });
      }
      this.setMapMarker(device.ID);
      if (this.filterDeviceID === device.ID && this.map) {
        this.moveMapToDevice(device.ID);
      }
    },
    onMessage(obj, sessionID, lnk) {
      switch (obj.Operation) {
        case "get_info":
          if (
            Array.isArray(obj.Object?.Devices) &&
            obj.Object?.Devices.length > 0
          ) {
            obj.Object.Devices.forEach((device) => {
              this.addOrUpdateDevice(device);
            });
          } else {
            lnk.currentStream.Devices = {};
          }
          lnk.loading = false;
          lnk.recalculateContainerEventsHeight();
          lnk.fetchEventsHistory();
          if (!this.timerPauseInterval) {
            this.timerPauseInterval = setInterval(() => {
              lnk.appendDrawData();
            }, this.drawIntervalMs);
          }
          break;
        case "device_connect":
          // here if device was reconnect, just show it again
          // find device in currentStream.Devices using SessionID. In lnk.currentStream.Devices key is ID
          // but in obj.Object.SessionID is SessionID
          // so we need to find device by SessionID

          const deviceID = Object.keys(lnk.currentStream.Devices).find(
            (key) =>
              lnk.currentStream.Devices[key].SessionID ===
                obj.Object.SessionID ||
              lnk.currentStream.Devices[key].SessionID2 ===
                obj.Object.SessionID,
          );

          const device = lnk.currentStream.Devices[deviceID];
          if (!device) {
            break;
          }

          device.Status = DEVICE_STATUS_ONLINE;
          break;
        case "device_disconnect":
          {
            const deviceID = Object.keys(lnk.currentStream.Devices).find(
              (key) =>
                lnk.currentStream.Devices[key].SessionID ===
                  obj.Object.SessionID ||
                lnk.currentStream.Devices[key].SessionID2 ===
                  obj.Object.SessionID,
            );

            const device = lnk.currentStream.Devices[deviceID];
            if (!device) {
              break;
            }

            device.Status = DEVICE_STATUS_OFFLINE;
            device.Arousal = 0;
            device.Valence = 0;
          }
          break;
        case "device_data":
          lnk.newDeviceData(obj.Object, true);
          break;
        case "device_info":
          lnk.addOrUpdateDevice(obj.Object);
          break;
        case "device_analytic":
          lnk.newAnalyticData(obj.Object);
          break;
      }
    },
    onError(obj, lastSend, lastOp) {
      let res = JSON.parse(obj);
      let lnk = this;
      if (lastOp == "generate_ticket_token") {
        // this error can't be if we check exists by http before
        Notify.create({
          group: true,
          timeout: 10000,
          message: lnk.$t("-raw-user-no-access"),
          color: "1",
          textColor: "n",
          classes: "round-both q-ml-lg q-mb-sm",
          position: "bottom",
        });
      } else if (lastOp == "message") {
        switch (lastSend?.Operation) {
          case "get_info":
            Notify.create({
              group: true,
              timeout: 10000,
              message:
                res?.ErrCode === "ret-2000"
                  ? res?.Err
                  : lnk.$t("-raw-error-occurred"),
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
            break;
        }
      }
    },
    onReady(lnk) {
      lnk.streamotionToken.send({
        Operation: "get_info",
        Object: {},
      });
    },
    streamotionConnect() {
      const lnk = this;
      lnk.streamotionToken = lnk.$streamer.addTicket(
        "streamotion_dashboard", // "classroom_c1065f10-d301-41fa-90a8-502d343189ef", // test
        lnk.currentStream.ID,
        function (obj, sessionID) {
          lnk.onMessage(obj, sessionID, lnk);
        },
        lnk.onError,
        lnk.sessionID,
        function () {
          lnk.onReady(lnk);
        },
      );
    },
    streamotionDisconnect(tm) {
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamotionToken !== null) {
            lnk.streamotionToken.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamotionToken !== null) {
          lnk.streamotionToken.removeTicket();
        }
      }
    },
    fetchEventsHistory() {
      const lnk = this;
      api
        .Call({
          url: `/api/v1/streamotion_stream/${lnk.currentStream.ID}/events?sortby=created_at&sortdir=desc`,
          method: "get",
        })
        .then(
          /** @param {StreamotionModelEvent[]} response */ (response) => {
            // we need to process last events, but starting from the oldest, so its desc and reverse
            setTimeout(() => {
              (response || []).reverse().forEach((item) => {
                lnk.newDeviceData(item, false);
              });
            }, 1000);
          },
          (e) => {
            console.error("error-9c5f5f44", "getStream", e);
          },
        );
    },
    getStreamByCode(code) {
      return new Promise((resolve, reject) => {
        api
          .Call({
            url: "/api/v1/streamotion_stream?code=" + code,
            show_error: false,
          })
          .then(
            (stream) => {
              if (stream) {
                resolve(stream);
              } else {
                reject({
                  error: "Undefined stream code",
                  code: "undefined_code",
                });
              }
            },
            (e) => {
              reject({ error: e, code: "E" });
              console.error("error-9c5f5f44", "getStreamByCode", e);
            },
          );
      });
    },
    getStreamByLink(link) {
      return new Promise((resolve, reject) => {
        api
          .Call({
            url: "/api/v1/streamotion_stream/link/" + link,
            show_error: false,
          })
          .then(
            (stream) => {
              if (stream) {
                resolve(stream);
              } else {
                reject({
                  error: "Undefined stream link",
                  code: "undefined_link",
                });
              }
            },
            (e) => {
              reject({ error: e, code: "E" });
              console.error("error-152b781d", "getStreamByLink", e);
            },
          );
      });
    },
    setStream(obj) {
      this.currentStream = obj;
      this.currentStream.Devices = this.currentStream.Devices || {};
      this.sessionID = uuidv4();
      this.streamotionDisconnect(); // actual if we close old stream (after generate new)
      this.streamotionConnect();
    },
    createStream() {
      this.creating = true;
      api
        .Call({
          url: "/api/v1/streamotion_stream/create",
          method: "post",
          data: this.newStream,
          show_error: true,
        })
        .then(
          (result) => {
            if (result) {
              this.setParamsUrl({ code: result.Code });
              if (result) {
                this.setStream(result);
              }
            }
            this.newStreamDialog = false;
            this.creating = false;
          },
          (e) => {
            this.creating = false;
          },
        );
    },
    joinLastStream() {
      this.creating = true;
      api
        .Call({
          url: "/api/v1/streamotion_stream/current",
          show_error: true,
        })
        .then(
          (result) => {
            if (result) {
              this.setParamsUrl({ code: result.Code });
              if (result) {
                this.setStream(result);
              }
            } else {
              this.$q.notify({
                type: "warning",
                message: i18n.global.t("-raw-streamotion-dash-no-last-stream"),
              });
              this.newStreamDialog = true;
            }
            // this.newStreamDialog = false;
            this.creating = false;
          },
          (e) => {
            this.creating = false;
          },
        );
    },
    parseParamsUrl() {
      if (!this.$router.currentRoute.value.query) {
        return;
      }

      this.paramsURL = {};
      var qr = this.$router.currentRoute.value.query;
      for (var name in qr) {
        this.paramsURL[name] = qr[name];
      }
    },
    setParamsUrl(params) {
      const query = Object.assign({}, this.$route.query);

      for (var name in params) {
        this.paramsURL[name] = encodeURIComponent(params[name]);
      }
      for (var name in this.paramsURL) {
        if (this.paramsURL[name]?.length > 0) {
          query[name] = this.paramsURL[name];
        } else {
          query[name] = "";
        }
      }

      this.$router.push({ query });
    },
    /**
     * @param {number} VBat
     * @returns {Object} {icon: string, color: string}
     * */
    batteryLevel(VBat) {
      if (
        VBat === undefined ||
        VBat === null ||
        typeof VBat !== "number" ||
        isNaN(VBat)
      ) {
        return null;
      }

      if (VBat >= 4.0) {
        return { icon: "battery_full", color: "positive" };
      } else if (VBat >= 3.9) {
        return { icon: "battery_5_bar", color: "positive" };
      } else if (VBat >= 3.7) {
        return { icon: "battery_4_bar", color: "warning" };
      } else if (VBat >= 3.5) {
        return { icon: "battery_2_bar", color: "negative" };
      } else if (VBat !== 0) {
        return { icon: "battery_alert", color: "negative" };
      } else {
        // zero volts means charging
        return { icon: "battery_charging_full", color: "primary" };
      }
    },
    /**
     * @typedef {Object & VocPedegree} StreamotionModelDevice
     * @property {string} OperationID
     * @property {string} SessionID
     * @property {string} SessionID2
     * @property {string} IMEI
     * @property {string} MAC
     * @property {string} Name
     * @property {number} AddressLat
     * @property {number} AddressLon
     * @property {boolean} Sleep
     */

    /**
     *  @typedef {Object & VocPedegree} StreamotionModelEvent
     *  @property {string} OperationID
     *  @property {string} SessionID
     *  @property {string} DeviceID
     *  @property {StreamotionModelDevice} Device
     *  @property {string} Name
     *  @property {number} Num
     *  @property {number} Valence
     *  @property {number} Arousal
     *  @property {number} GunShot
     *  @property {number} GlassBreaking
     *  @property {number} Latitude
     *  @property {number} Longitude
     *  @property {number} Altitude
     *  @property {number} Accuracy
     *  @property {Date} GPSTimestamp
     *  @property {number} VBat
     *  @property {string} IMEI
     *  @property {string} ParsedSeekEvents
     *  @property {string} RPCError
     *  @property {string} FileID
     */

    /**
     * Handles new device data.
     *
     * @returns {void}
     * @param {StreamotionModelEvent} data
     * @param {boolean} isCurrentData
     */
    async newDeviceData(data, isCurrentData) {
      const deviceID =
        data.DeviceID ||
        Object.keys(this.currentStream?.Devices).find(
          (key) =>
            this.currentStream.Devices[key].SessionID === data.SessionID ||
            this.currentStream.Devices[key].SessionID2 === data.SessionID,
        );
      let device = this.currentStream?.Devices[deviceID];
      if (!device) {
        if (!data.Device) {
          const dev = await api.Call({
            url: `/api/v1/streamotion_stream/${this.currentStream.ID}/devices?sessionids=${data.SessionID}`,
            method: "get",
          });
          if (dev && dev.length > 0) {
            this.addOrUpdateDevice(dev[0]);
            device = this.currentStream.Devices[dev[0].ID];
            data.DeviceID = dev[0].ID;
          }
        } else {
          this.addOrUpdateDevice(data.Device);
          device = this.currentStream.Devices[data.Device.ID];
        }
      }
      if (isCurrentData) {
        device.Arousal = data.Arousal;
        device.Valence = data.Valence;
        device.Status = DEVICE_STATUS_ONLINE;
        device.LastAnswer = Date.now();
      } else {
        device.Status = DEVICE_STATUS_OFFLINE;
      }
      device.VBat = data.VBat;
      device.Latitude = device.Latitude || data.Latitude;
      device.Longitude = device.Longitude || data.Longitude;
      device.Altitude = device.Altitude || data.Altitude;
      device.Accuracy = device.Accuracy || data.Accuracy;
      device.GPSTimestamp = data.GPSTimestamp;
      device.MediaType = data.MediaType;

      const batteryLevel = this.batteryLevel(data.VBat);
      device.BatteryIcon = batteryLevel?.icon;
      device.BatteryColor = batteryLevel?.color;

      if (isCurrentData && this.autoPlayDeviceID === device.ID) {
        this.playEvent(data.FileID);
      }

      // put event on the top of the list
      let pnt = Lib.calculateEmotionPoint({
        Arousal: data.Arousal,
        Valence: data.Valence,
      });
      device.EmotionColor = pnt.color;
      pnt.DeviceID = device.ID;
      pnt.device = device.Name;
      pnt.date = Lib.formatTimeByLocale(null, new Date(data.createdat));
      pnt.tooltip = Lib.capitalize(pnt.tooltip);
      pnt.FileID = data.FileID;
      this.eventsList.splice(0, 0, pnt);
      // keep eventsList limited
      if (this.eventsList.length > this.maxCountEvents) {
        const removedDeviceID =
          this.eventsList[this.eventsList.length - 1].DeviceID;
        this.eventsList.splice(this.eventsList.length - 1, 1);
        // check if removed sessionID is no more in eventsList, then remove from drawDataMap, marker and device
        if (!this.eventsList.some((e) => e.DeviceID === removedDeviceID)) {
          this.setMapMarker(removedDeviceID);
          if (this.currentStream.Devices[removedDeviceID]) {
            this.currentStream.Devices[removedDeviceID].Status =
              DEVICE_STATUS_INACTIVE;
          }
        }
      }

      this.setMapMarker(device.ID);
    },
    // show, hide or update marker on the map
    // for online and offline devices we show marker by default
    // for inactive devices we show marker only if showInactiveDevices is true
    setMapMarker(deviceID) {
      const device = this.currentStream.Devices[deviceID];
      if (!device) {
        return;
      }
      // we can't store markers in this.currentStream.Devices, because we might get circular reference, marker is an object
      const marker = this.markersMap.value.get(deviceID);
      // hide marker if device is inactive if showInactiveDevices is false
      if (device.Status === DEVICE_STATUS_INACTIVE && !this.showInactiveDevices) {
        if (marker) {
          marker.setMap(null);
          this.markersMap.value.delete(deviceID);
        }
        return;
      }
      if (!this.map || !device.Latitude || !device.Longitude) {
        return;
      }
      if (!marker) {
        // note-821de384 for AdvancedMarkerElement
        // pin = new this.$mapPinElement({
        //   scale: 1.5,
        // });

        // note-821de384 for AdvancedMarkerElement
        // marker = new this.$mapAdvancedMarkerElement({
        //   position: {
        //     lat: device.Latitude,
        //     lng: device.Longitude,
        //     altitude: device.Altitude,
        //   },
        //   map: this.map,
        //   title: this.generateDeviceName(sessionID, device.IMEI),
        //   content: device.pin.element,
        // });
        const svgMarker = {
          path: "M 0 0 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z",
          fillColor: device.EmotionColor,
          fillOpacity: 0.9,
          strokeWeight: 0,
          rotation: 0,
          scale: 2,
          anchor: new google.maps.Point(0, 20),
        };

        const marker = new google.maps.Marker({
          position: {
            lat: device.Latitude,
            lng: device.Longitude,
            altitude: device.Altitude,
          },
          map: this.map,
          icon: svgMarker,
          label: device.Name,
        });
        this.markersMap.value.set(deviceID, marker);
      } else {
        // note-821de384 for AdvancedMarkerElement
        // marker.position = {
        //   lat: device.Latitude,
        //   lng: device.Longitude,
        //   altitude: device.Altitude,
        // };
        const icon = marker.getIcon();
        icon.fillColor = device.EmotionColor;
        marker.setIcon(icon);
        marker.label = device.Name;
        marker.setPosition({
          lat: device.Latitude,
          lng: device.Longitude,
          altitude: device.Altitude,
        });
      }
    },
    moveMapToDevice(deviceID) {
      const marker = this.markersMap.value.get(deviceID);
      if (marker) {
        // note-821de384 for AdvancedMarkerElement
        // this.map.setCenter(marker.position);
        this.map.setCenter(marker.getPosition());
        this.map.setZoom(19);
        // save new position and zoom in local storage
        localStorage.setItem(
          "map_position",
          JSON.stringify(marker.getPosition()),
        );
        localStorage.setItem("map_zoom", JSON.stringify(14));
      }
    },
    onDeviceClick(deviceID) {
      this.moveMapToDevice(deviceID);
      this.filterDeviceID = this.filterDeviceID === deviceID ? null : deviceID;
    },
    newAnalyticData(data) {
      this.analytics = {
        analytic: data.Analytic,
        date: Lib.formatTimeByLocale(null, new Date(data.Time)),
      };

      if (data.AudioSampleRefID && this.voiceAnalytics) {
        this.playAnalytic(data.AudioSampleRefID);
      }
    },
    appendDrawData() {
      // this.drawDataMap.forEach((value, key, map) => {
      Object.keys(this.currentStream.Devices).forEach((key) => {
        const device = this.currentStream.Devices[key];
        if (device?.ID) {
          // if last answer old use 0/undefined
          if (
            device.LastAnswer &&
            Math.abs(device.LastAnswer - Date.now()) > 13000
          ) {
            device.Valence = 0;
            device.Arousal = 0;
          }
          if (
            this.$refs[key + "_refd"] &&
            this.$refs[key + "_refd"].length > 0
          ) {
            const lnk = this;
            lnk.$refs[key + "_refd"][0].addOrRemovePoint(
              {
                Arousal: device.Arousal,
                Valence: device.Valence,
              },
              lnk.drawCountSteps - 1,
            );
          }
        }
      });
    },
    copyLinkToClipboard(text) {
      const lnk = this;
      this.$copyText(text)
        .then(
          function (e) {
            lnk.linkIsCopied = true;
          },
          function (e) {
            lnk.linkIsCopied = false;
          },
        )
        .then(
          setTimeout(function () {
            lnk.linkIsCopied = null;
          }, 800),
        );
    },
    toggleStreamPublic() {
      const lnk = this;
      return api
        .Call({
          url: `/api/v1/streamotion_stream/${lnk.currentStream.ID}/toggle_public`,
          method: "post",
        })
        .then(
          /**
           * @typedef {Object} VocPedegree
           * @property {string} ID
           * @property {string} Version
           * @property {string} VersionID
           * @property {Date} CreatedAt
           * @property {Date} UpdatedAt
           * @property {Date} DeletedAt
           * @property {string} CreatedBy
           * @property {string} UpdatedBy
           * @property {string} DeletedBy
           */

          /**
           * @typedef {VocPedegree & Object} Stream
           * @property {string} Code
           * @property {string} Link
           * @property {boolean} TTS
           * @property {string[]} SleepIMEIs
           */

          /** @param {Stream} response */ (response) => {
            lnk.currentStream.Link = response.Link;
          },
          (e) => {
            console.error("error-a50cf286", "toggleStreamPublic", e);
          },
        );
    },
    toggleStreamTTS() {
      const lnk = this;
      return api
        .Call({
          url: `/api/v1/streamotion_stream/${lnk.currentStream.ID}/toggle_tts`,
          method: "post",
        })
        .then(
          /** @param {Stream} response */ (response) => {
            lnk.currentStream.TTS = response.TTS;
          },
          (e) => {
            console.error("error-284cf232", "toggleStreamTTS", e);
          },
        );
    },
    recalculateContainerEventsHeight() {
      setTimeout(() => {
        let elem = document.getElementById("page_dashboard_" + this.uid);
        if (elem) {
          this.containerEventsHeight = this.$route.params.link
            ? elem.getBoundingClientRect().height
            : elem.getBoundingClientRect().height - 100;
        }

        let theme = this.$store.getters.getTheme;
        if (theme && theme.length > 0) {
          theme = theme[theme.length - 1];
        }
        // destroy and create map
        if (this.map) {
          this.map = null;
        }

        if (document.getElementById("map_" + this.uid)) {
          this.$googleMap.load().then(async () => {
            const { Map } = await google.maps.importLibrary("maps");

            let elem = document.getElementById("page_dashboard_" + this.uid);
            if (elem) {
              this.containerDevicesTop = elem.getBoundingClientRect().top - 10;
            }

            let map_position = Lib.safeJsonParse(
              localStorage.getItem("map_position"),
            );
            if (!(map_position?.lat && map_position?.lng)) {
              map_position = { lat: 35.2722618, lng: -120.6910795 };
              localStorage.setItem(
                "map_position",
                JSON.stringify(map_position),
              );
            }

            let map_zoom = Lib.safeJsonParse(localStorage.getItem("map_zoom"));
            if (!(map_zoom > 0)) {
              map_zoom = 12;
              localStorage.setItem("map_zoom", JSON.stringify(map_zoom));
            }

            this.map = new Map(document.getElementById("map_" + this.uid), {
              center: map_position,
              mapId: "aa230af88e1d2ace",
              zoom: map_zoom,
              disableDefaultUI: true, // Hides all UI controls
              zoomControl: false, // Hides zoom buttons (+/-)
              fullscreenControl: false, // Hides fullscreen button ([ ])
            });
            const { AdvancedMarkerElement, PinElement } =
              await google.maps.importLibrary("marker");

            // save position and zoom on change in local storage with debounce
            let timeout_center = null;
            this.map.addListener("center_changed", () => {
              if (timeout_center) {
                clearTimeout(timeout_center);
              }
              timeout_center = setTimeout(() => {
                localStorage.setItem(
                  "map_position",
                  JSON.stringify(this.map.getCenter()),
                );
              }, 1000);
            });

            let timeout_zoom = null;
            this.map.addListener("zoom_changed", () => {
              if (timeout_zoom) {
                clearTimeout(timeout_zoom);
              }
              timeout_zoom = setTimeout(() => {
                localStorage.setItem(
                  "map_zoom",
                  JSON.stringify(this.map.getZoom()),
                );
              }, 1000);
            });

            this.AdvancedMarkerElement = AdvancedMarkerElement;
          });
        }
      }, 100);
    },
    async playAudio(oggResponse) {
      this.audioBlobURL = URL.createObjectURL(
        new Blob([oggResponse], { type: "audio/ogg" }),
      );
      const fileArray = await Lib.transcode(
        this.audioBlobURL,
        "audio.ogg",
        "wavNormalize",
        null,
        false,
        this.jsBlobURL,
        this.wasmBlobURL,
      );
      URL.revokeObjectURL(this.audioBlobURL);
      this.audioBlobURL = URL.createObjectURL(
        new Blob([fileArray], { type: "audio/wav" }),
      );

      this.stopAllAudio(false);
      this.audioEvent = new Audio(this.audioBlobURL);
      this.audioEvent.play();
      this.audioEvent.addEventListener("ended", () => {
        this.stopAllAudio();
      });
    },
    playEvent(fileID) {
      const lnk = this;
      api
        .Call({
          url: "/api/v1/asset/file/streamotion_events/" + fileID,
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "audio/ogg",
          },
        })
        .then(
          (result) => {
            setTimeout(() => lnk.playAudio(result), 200);
          },
          (e) => {
            Notify.create({
              group: true,
              timeout: 10000,
              message: lnk.$t("-raw-error-occurred"),
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
            console.error("error", "get audio: ", e);
          },
        );
    },
    playAnalytic(audiosamplerefid) {
      const lnk = this;
      api
        .Call({
          url: "/api/v1/asset/object/audiosample/ref/" + audiosamplerefid,
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "audio/ogg",
          },
        })
        .then(
          (result) => lnk.playAudio(result),
          (e) => {
            Notify.create({
              group: true,
              timeout: 10000,
              message: lnk.$t("-raw-error-occurred"),
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
            console.error("error", "get audio: ", e);
          },
        );
    },
    stopAllAudio(revoke = true) {
      if (this.audioAnalytics) {
        this.audioAnalytics.pause();
        this.audioAnalytics = null;
      }
      if (this.audioEvent) {
        this.audioEvent.pause();
        this.audioEvent = null;
      }
      if (revoke && this.audioBlobURL) {
        URL.revokeObjectURL(this.audioBlobURL);
        this.audioBlobURL = null;
      }
    },
    clearFilter() {
      this.filterDeviceID = null;
      if (this.autoPlayDeviceID) {
        this.autoPlayDeviceID = null;
        this.stopAllAudio();
      }
    },
    toggleVoiceAnalytics() {
      this.voiceAnalytics = !this.voiceAnalytics;
      if (this.voiceAnalytics) {
        this.autoPlayDeviceID = null;
        this.stopAllAudio();
      }
    },
    toggleAutoPlay(deviceID) {
      if (
        !this.currentStream.Devices[deviceID]?.Status === DEVICE_STATUS_ONLINE
      ) {
        return;
      }
      this.autoPlayDeviceID =
        this.autoPlayDeviceID === deviceID ? null : deviceID;
      this.voiceAnalytics = false;
      this.stopAllAudio();
    },
    toggleSleep(deviceID) {
      {
        const lnk = this;
        return api
          .Call({
            url: `/api/v1/streamotion_device/${deviceID}/toggle_sleep`,
            method: "post",
          })
          .then(
            /** @param {StreamotionModelDevice} response */ (response) => {
              lnk.currentStream.Devices[response.ID].Sleep = response.Sleep;
            },
            (e) => {
              console.error("error-fb7cb7aa", "toggleSleep", e);
            },
          );
      }
    },
    toggleShowInactiveDevices() {
      this.showInactiveDevices = !this.showInactiveDevices;
      Object.keys(this.currentStream.Devices).forEach((deviceID) => {
        this.setMapMarker(deviceID);
      });
    },
    /* @param {Device} device */
    editItem(device) {
      this.editDevice.ID = device.ID;
      this.editDevice.Name = device.Name;
      this.editDevice.AddressLat = device.AddressLat;
      this.editDevice.AddressLon = device.AddressLon;
      const lnk = this;
      this.$nextTick(() => {
        if (lnk.$refs["edititem_" + device.ID]?.length) {
          lnk.$refs["edititem_" + device.ID][0].focus();
        }
      });
      // set map listener to show marker in clicked place and update lat/lon
      if (this.map) {
        const listener = this.map.addListener("click", (e) => {
          lnk.editDevice.AddressLat = e.latLng.lat();
          lnk.editDevice.AddressLon = e.latLng.lng();
          if (lnk.editDeviceMarker?.value) {
            lnk.editDeviceMarker.value.setMap(null);
          }

          const marker = new google.maps.Marker({
            position: {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            },
            map: lnk.map,
            label: lnk.editDevice.Name,
          });
          // delete marker on click
          marker.addListener("click", () => {
            marker.setMap(null);
            lnk.editDeviceMarker = null;
            lnk.editDevice.AddressLat = device.AddressLat;
            lnk.editDevice.AddressLon = device.AddressLon;
          });
          lnk.editDeviceMarker = shallowRef({});
          lnk.editDeviceMarker.value = marker;
        });
        this.editDeviceListener = shallowRef({});
        this.editDeviceListener.value = listener;
      }
    },
    editItemCancel() {

      if (this.editDeviceListener?.value) {
        google.maps.event.removeListener(this.editDeviceListener.value);
      }

      if (this.editDeviceMarker?.value) {
        this.editDeviceMarker.value.setMap(null);
        this.editDeviceMarker = null;
      }

      this.editDevice = Object.assign({}, emptyEditDevice);
    },
    editItemApply() {
      const lnk = this;
      return api
        .Call({
          url: `/api/v1/streamotion_device`,
          method: "post",
          data: this.editDevice,
        })
        .then(
          /** @param {StreamotionModelDevice} response */ (response) => {
            lnk.addOrUpdateDevice(response);
          },
          (e) => {
            console.error("error-e98867c5", "editItemApply", e);
            // notify
            lnk.$q.notify({
              type: "negative",
              message: i18n.global.t("-raw-error-occurred") + ": " + e,
            });
          },
        )
        .finally(() => {
          lnk.editItemCancel();
        });
    },
  },
  computed: {
    link: function () {
      return `${process.env.PUBLIC_URL}/streamotion-link/${this.currentStream.Link}`;
    },
    deviceCount: function () {
      return Object.keys(this.currentStream.Devices).length;
    },
    isSystem() {
      return Lib.getItem("VOC_USER_ROLE_IS_SYSTEM");
    },
  },
};

<script src="./index.js"></script>
<template>
  <q-page
    :id="'page_dashboard_' + uid"
    class="boxshadow no-wrap bg-1 column full-width"
    style="overflow-y: hidden"
  >
    <div
      v-if="loading === true"
      class="column row full-width items-center q-pa-md"
    >
      <q-spinner-ball color="primary" size="lg" />
    </div>

    <div v-else class="fit">
      <q-card v-if="!$route.params.link" class="full-width" flat>
        <div
          :id="'div_new_stream' + uid"
          class="row full-width justify-left items-left q-pa-sm"
          style="
            height: 4.5151rem;
            position: relative;
            z-index: 10;
            border-radius: 0;
            background: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(8px);
          "
        >
          <div v-if="currentStream?.ID">
            <q-input
              v-model="currentStream.Code"
              :label="$t('-raw-streamotion-dash-current-stream-code')"
              borderless
              class="bg-2 q-px-sm"
              input-class="ubuntu bg-transparent"
              input-style="color: var(--q-a);"
              style="border-radius: 1rem; max-width: 12rem"
              type="text"
            />
          </div>
          <div class="col-6">
            <q-toggle
              v-if="currentStream?.ID"
              :model-value="!!currentStream.Link"
              color="primary"
              label="Public"
              style="margin-left: 0.5rem"
              @update:model-value="toggleStreamPublic"
            />
            <q-btn
              v-if="currentStream.Link"
              class="q-ml-sm text-e"
              dense
              flat
              icon="content_copy"
              round
              size="sm"
              @click.prevent="copyLinkToClipboard(link)"
            >
              <q-tooltip
                :class="
                  linkIsCopied === null
                    ? 'text-v'
                    : linkIsCopied === true
                      ? 'text-p'
                      : 'text-n'
                "
                anchor="top right"
                class="mytooltip round-both ubuntu bg-0 row items-center q-px-md"
                self="bottom right"
                transition-hide="jump-left"
                transition-show="jump-right"
              >
                <div class="column items-center" style="width: 8em">
                  <span v-if="linkIsCopied === null">
                    {{ $t("-raw-anomaly-copy-link") }}
                  </span>
                  <span v-else-if="linkIsCopied === true">
                    {{ $t("-raw-tooltip-copied") }}
                  </span>
                  <span v-else>
                    {{ $t("-raw-op-error") }}
                  </span>
                </div>
              </q-tooltip>
            </q-btn>
            <q-toggle
              v-if="currentStream?.ID"
              :model-value="!!currentStream.TTS"
              color="primary"
              label="TTS"
              style="margin-left: 0.5rem"
              @update:model-value="toggleStreamTTS"
            />
            <q-toggle :model-value="showInactiveDevices" @click.stop="toggleShowInactiveDevices" class="q-ml-sm" label="All devices" />
          </div>

          <div v-if="true" style="position: absolute; right: 1rem">
            <q-btn
              :loading="creating"
              class="bg-v text-f create q-mr-md ubuntu"
              flat
              no-caps
              style="border-radius: 1rem"
              @click="newStreamDialog = true"
            >
              <div v-if="$q.screen.gt.md" class="gt-sm q-mr-lg">
                {{ $t("-raw-streamotion-dash-new-stream") }}
              </div>
              <q-icon class="create-icon" name="addchart" />
              <q-tooltip
                v-if="!$q.screen.gt.md"
                anchor="top right"
                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                self="bottom right"
                transition-hide="jump-left"
                transition-show="jump-right"
              >
                {{ $t("-raw-streamotion-dash-new-stream") }}
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="!currentStream?.ID"
              :loading="creating"
              class="bg-v text-f q-mr-md ubuntu"
              flat
              no-caps
              style="border-radius: 1rem"
              @click="joinLastStream"
            >
              <q-icon name="stream" />
              <q-tooltip
                anchor="top right"
                class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                self="bottom right"
                transition-hide="jump-left"
                transition-show="jump-right"
              >
                {{ $t("-raw-streamotion-dash-join-last-stream") }}
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </q-card>
      <div
        v-if="currentStream?.ID"
        :class="$q.screen.gt.sm ? 'row' : 'column full-width'"
        class="col q-mx-md q-my-lg"
      >
        <div
          :class="
            $q.screen.gt.sm
              ? 'col-xs-10 col-sm-8 col-md-8 col-lg-9 col-xl-9'
              : ''
          "
        >
          <div
            v-show="deviceCount > 0"
            :id="'map_' + uid"
            class="full-width full-height q-pt-lg"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              z-index: 0;
            "
          ></div>

          <div
            :style="{
              left: $q.screen.gt.sm ? 'auto' : '0.5rem',
              top: containerDevicesTop ? containerDevicesTop + 'px' : 'auto',
              width: $q.screen.gt.sm ? '22rem' : '95vw',
              maxHeight: containerEventsHeight + 'px',
              borderRadius: '0.5rem', // Ensure corners are rounded
              overflow: 'hidden', // Prevents any content from breaking out
              paddingRight: '1px', // Small padding fix for rounding cut issue
              boxSizing: 'border-box', // Ensures consistent sizing including padding
            }"
            class="q-pt-lg"
            style="
              position: absolute;
              border-radius: 0.5rem;
              background: rgba(0, 0, 0, 0);
            "
          >
            <q-scroll-area
              :style="{
                height: deviceCount * 110 + 'px',
                maxHeight:`calc(${containerEventsHeight}px)`
              }"
              class="q-pb-xl"
            >
              <div
                v-for="device in (
                  Object.values(currentStream?.Devices) || []
                ).filter(
                  showInactiveDevices
                    ? () => true
                    : (d) => d.Status >= DEVICE_STATUS_OFFLINE,
                )"
                :key="'div_device_' + device?.ID"
                class="column full-width"
              >
                <div
                  class="row q-py-sm q-mb-xs"
                  style="
                    border-radius: 0.8rem;
                    font-size: 0.9rem;
                    backdropfilter: blur(8px);
                    background: rgba(0, 0, 0, 0.7);
                  "
                  :style="{width: $q.screen.gt.sm ? '22rem !important' : '95vw !important'}"
                  @click="onDeviceClick(device?.ID)"
                >
                  <div class="column col-10">
                    <div
                      class="row no-wrap text-no-wrap full-width text-e text-bold q-px-md cursor-pointer items-center"
                    >
                      <q-spinner-rings
                        :color="
                          device?.Status === DEVICE_STATUS_ONLINE ? 'p' : 'w'
                        "
                      />
                      <span class="q-pl-sm col scroll-x no-wrap" style="scrollbar-width: thin" v-if="device.ID !== editDevice?.ID">
                        {{device.Name}}
                        <q-tooltip
                          v-if="device.IMEI"
                          anchor="bottom right"
                          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md text-no-wrap"
                          self="top right"
                          transition-hide="jump-left"
                          transition-show="jump-right"
                        >
                          IMEI: {{ device.IMEI }}
                        </q-tooltip>
                      </span>
                      <!--Name editor-->
                      <div
                        v-else
                        class="q-pl-sm row col no-wrap justify-center items-center"
                      >
                        <q-input
                          :ref="'edititem_' + device.ID"
                          v-model="editDevice.Name"
                          borderless
                          class="bg-0 col edit-style"
                          dense
                          input-class="ubuntu text-center col no-wrap scroll-x"
                          input-style="color: var(--q-a); font-size: 0.8rem; height: 2rem; scrollbar-width: thin"
                          style="
                                border-radius: 1rem;
                                font-size: 1rem;
                                height: 2.2rem;
                              "
                          type="text"
                          @click.stop=""
                          @keyup.enter="editItemApply(device.ID)"
                          @keyup.esc="editItemCancel"
                          @focus="(input) => input.target.select()"
                        />
                        <q-btn
                          :disable="!editDevice.Name"
                          class="bg-0 edit-style"
                          flat
                          round
                          size="xs"
                          @click.stop="editItemApply(device.ID)"
                        >
                          <q-icon color="p" name="done" />
                        </q-btn>
                        <q-btn
                          class="bg-0 edit-style"
                          flat
                          round
                          size="xs"
                          @click.stop="editItemCancel"
                        >
                          <q-icon color="n" name="cancel" />
                        </q-btn>
                      </div>

                      <q-btn
                        v-if="isSystem && device.ID !== editDevice?.ID"
                        :style="
                        device.ID !== editDevice?.ID
                          ? 'opacity: 1'
                          : 'opacity: 0'
                      "
                        class="q-pl-sm bg-transparent transparent cursor-pointer"
                        flat
                        icon="edit"
                        round
                        size="xs"
                        style="cursor: default; width: 1rem; height: 1rem"
                        @click.stop="editItem(device)"
                      >
                        <q-tooltip
                          anchor="top right"
                          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                          self="bottom right"
                          transition-hide="jump-left"
                          transition-show="jump-right"
                        >
                          {{ $t("-raw-general-edit-name") }}
                        </q-tooltip>
                      </q-btn>
                      <q-icon
                        v-if="device.IMEI"
                        name="content_copy"
                        class="q-pl-sm"
                        @click.stop="copyLinkToClipboard(device.IMEI)"
                      >
                        <q-tooltip
                          anchor="top right"
                          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                          self="bottom right"
                          transition-hide="jump-left"
                          transition-show="jump-right"
                        >
                          copy IMEI to clipboard
                        </q-tooltip>
                      </q-icon>
                      <q-icon
                        v-if="device.BatteryIcon"
                        :color="device.BatteryColor"
                        :name="device.BatteryIcon"
                        size="sm"
                        class="q-pl-sm"
                      >
                        <q-tooltip
                          anchor="top right"
                          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                          self="bottom right"
                          transition-hide="jump-left"
                          transition-show="jump-right"
                        >
                          {{ device.VBat }}V
                        </q-tooltip>
                      </q-icon>
                      <q-icon
                        v-if="device.MediaType"
                        :name="
                          device.MediaType === 'WiFi' ? 'wifi' : 'sim_card'
                        "
                        color="5"
                        size="sm"
                        class="q-pl-sm"
                      >
                        <q-tooltip
                          anchor="top right"
                          class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                          self="bottom right"
                          transition-hide="jump-left"
                          transition-show="jump-right"
                        >
                          {{ device.MediaType }}
                        </q-tooltip>
                      </q-icon>
                    </div>
                    <div class="row full-width q-pt-md q-pl-md">
                      <div class="row full-width">
                        <!-- {{ drawDataMap.get(device.ID) }} -->
                        <streamotionAnalysis
                          :id="device.ID"
                          :ref="device.ID + '_refd'"
                          :data="{
                            uid: device.ID + '_SA',
                            list: {
                              uid: uid,
                              data: device.GraphData,
                            },
                          }"
                          :view="{
                            height: 35,
                            width: $q.screen.gt.sm
                              ? `calc(${window.innerWidth * 0.2 - 20}px)`
                              : `calc(${window.innerWidth * 0.4}px)`,
                          }"
                          style="height: 3rem; width: 95%"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col column items-center justify-center">
                    <q-icon
                      :color="
                        device.Status < DEVICE_STATUS_ONLINE
                          ? '5'
                          : autoPlayDeviceID === device.ID
                            ? 'p'
                            : 'e'
                      "
                      :name="
                        autoPlayDeviceID === device.ID
                          ? 'pause'
                          : 'play_arrow'
                      "
                      size="md"
                      @click="toggleAutoPlay(device.ID)"
                    />
                    <q-icon
                      v-if="!!device.IMEI && isSystem"
                      :color="device.Sleep ? 'p' : 'e'"
                      class="q-pt-md"
                      name="bedtime"
                      size="xs"
                      @click.stop="toggleSleep(device.ID)"
                    />
                  </div>
                </div>
              </div>
            </q-scroll-area>
          </div>
        </div>

        <div
          v-show="deviceCount > 0"
          :style="{
            position: 'absolute',
            left: $q.screen.gt.sm ? 'auto' : '0.5rem',
            right: $q.screen.gt.sm ? '0.5rem' : 'auto',
            top: $q.screen.gt.sm ? '6rem' : '30rem',
            bottom: '0.5rem',
            width: $q.screen.gt.sm ? '25.66vw' : '96vw',
            background: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(8px)',
            zIndex: 10,
            padding: '10px',
            overflow: 'hidden', // Prevent scrolling inside
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }"
          style="border-radius: 0.8rem"
        >
          <div class="q-mx-sm q-mt-sm">
            <div
              :style="{
                height: containerEventsHeight + 'px',
              }"
              class="q-px-sm"
            >
              <hr v-if="$q.screen.gt.sm" />
              <div
                :style="{
                  height: containerEventsHeight / 4 + 'px',
                }"
                class="row"
              >
                <div
                  class="row text-bold items-center"
                  style="font-size: 1.23rem"
                >
                  <span class="text-0">
                    {{ $t("-raw-streamotion-dash-analytics") }}
                  </span>
                  <div class="col" />
                  <q-toggle
                    v-if="currentStream.TTS"
                    :model-value="voiceAnalytics"
                    icon="campaign"
                    @click="stopAllAudio"
                    @update:model-value="toggleVoiceAnalytics"
                  >
                    <q-tooltip
                      anchor="top right"
                      class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
                      self="bottom right"
                      transition-hide="jump-left"
                      transition-show="jump-right"
                    >
                      Text-to-speech
                    </q-tooltip>
                  </q-toggle>
                </div>
                <div class="row text-bold fit" style="flex-grow: 1">
                  <template v-if="analytics">
                    <q-scroll-area
                      class="row items-center justify-center full-width"
                      style="font-size: 0.9rem"
                    >
                      <transition appear name="slidedown">
                        <div class="fit msg-div row">
                          <span class="q-pr-sm">{{ analytics.date }}: </span>
                          <br />
                          <span>{{ analytics.analytic }}</span>
                        </div>
                      </transition>
                    </q-scroll-area>
                  </template>

                  <template v-else>
                    <p class="text-e">AI Analysis is in Progress</p>
                    <div
                      class="row justify-center items-center full-width"
                      style="height: 100%"
                    >
                      <div class="ai-spinner">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <hr v-if="$q.screen.gt.sm" />
              <div
                v-if="$q.screen.gt.sm"
                :style="{
                  height: containerEventsHeight / 3 + 'px',
                }"
                class="col"
              >
                <div
                  class="row text-bold items-center"
                  style="font-size: 1.23rem"
                >
                  <span class="text-0">{{
                    $t("-raw-streamotion-dash-events")
                  }}</span>
                  <div class="col" />
                  <q-chip
                    v-if="filterDeviceID"
                    :label="currentStream.Devices[filterDeviceID].Name"
                    class="bg-2 text-e q-mx-sm"
                    icon="filter_alt"
                    removable
                    @remove="clearFilter"
                  />
                </div>
                <div class="row text-bold fit">
                  <q-scroll-area
                    :style="`height: calc(${containerEventsHeight / 1.5}px - 0rem)`"
                    class="row items-center justify-center full-width"
                    style="font-size: 0.9rem"
                  >
                    <div
                      v-for="(event, num) in (eventsList || []).filter(
                        (e) =>
                          !filterDeviceID || e.DeviceID === filterDeviceID,
                      )"
                      :key="'event_' + num"
                      class="full-width q-pa-xs no-wrap scroll-x"
                    >
                      <transition appear name="slidedown">
                        <div class="fit msg-div row text-no-wrap no-wrap scroll-x">
                          <span>{{ event.device }}</span>
                          <q-separator
                            class="separator-events-tr q-mx-sm"
                            vertical
                          ></q-separator>
                          <span>{{ event.date }} </span>
                          <q-separator
                            class="separator-events-tr q-mx-sm"
                            vertical
                          ></q-separator>
                          <span
                            :style="{
                              color:
                                event.tooltip.toLowerCase() === 'aggressive'
                                  ? 'red'
                                  : 'white',
                              WebkitTextStroke: '0.1px black',
                              textStroke: '0.1px black',
                            }"
                            class="q-mr-sm"
                            >{{ event.tooltip }}</span
                          >
                          <div class="col" />
                          <q-btn
                            v-if="event.FileID"
                            class="bg-2 text-e q-mx-sm"
                            flat
                            icon="play_arrow"
                            no-caps
                            size="xs"
                            style="border-radius: 0.5rem"
                            @click="playEvent(event.FileID)"
                          />
                        </div>
                      </transition>
                    </div>
                  </q-scroll-area>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="deviceCount === 0"
          class="row full-width items-center justify-center q-pt-md"
        >
          <span class="text-bold q-pb-sm" style="font-size: 1.4rem">{{
            $t("-raw-streamotion-dash-no-device")
          }}</span>
        </div>

        <!-- <div class="row full-width">
          <span class="text-bold q-pb-sm" style="font-size: 1.1rem">{{
            $t("-raw-streamotion-dash-current-stream")
          }}</span>
        </div>
        <div class="row full-width">
          <q-input
            type="text"
            :label="$t('-raw-streamotion-dash-code')"
            borderless
            clearable
            v-model="currentStream.Code"
            style="border-radius: 1rem; max-width: 20rem"
            class="glassy-8 q-px-md bg-transparent"
            input-class="ubuntu bg-transparent"
            input-style="color: var(--q-a);"
            readonly
          />
        </div> -->
      </div>
    </div>

    <q-dialog v-model="newStreamDialog">
      <q-card
        class="bg-1 no-shadow"
        style="border-radius: 1rem; width: 500px; max-width: 80vw"
      >
        <q-card-section class="column col full-width q-ma-md">
          <span class="text-bold q-pb-sm" style="font-size: 1.1rem">{{
            $t("-raw-streamotion-dash-new-stream")
          }}</span>
          <q-input
            :ref="'qDialogCode_' + uid"
            v-model="newStream.Code"
            :label="$t('-raw-streamotion-dash-code')"
            borderless
            class="glassy-8 q-px-md full-width bg-transparent q-mt-sm"
            clearable
            input-class="ubuntu bg-transparent"
            input-style="color: var(--q-a);"
            style="border-radius: 1rem"
            type="text"
          />
        </q-card-section>
        <q-card-actions align="right" class="q-px-md q-pb-md">
          <!-- <q-btn
               style="border-radius: 1rem"
               no-caps
               flat
               class="bg-v text-f q-mr-md ubuntu"
               @click="joinLastStream"
               :loading="creating"
               v-if="!currentStream?.ID"
               >
               <div class="gt-sm q-mx-md">
               {{ $t("-raw-streamotion-dash-join-last-stream") }}
               </div>
               <q-icon name="stream" />
               </q-btn> -->
          <q-btn
            v-close-popup
            :label="$t('-raw-general-cancel')"
            :loading="creating"
            class="bg-2 q-mr-sm"
            color="8"
            flat
            no-caps
            style="border-radius: 0.5rem"
          />
          <q-btn
            :disabled="!newStream.Code"
            :label="$t('-raw-route-create')"
            :loading="creating"
            class="bg-v text-0 q-px-lg"
            flat
            no-caps
            style="border-radius: 0.5rem"
            @click="createStream()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<style scoped>
.create-icon {
  transition-duration: 0.2s;
}

.create:hover .create-icon {
  transform: rotate(90deg);
}

.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}

.separator-events-tr {
  height: 1.2rem;
  background: var(--q-e);
}

.ai-spinner {
  display: flex;
  font-size: 3rem;
  font-weight: bold;
  color: var(--q-e);
}

.ai-spinner span {
  animation: blink 1.5s infinite;
}

.ai-spinner span:nth-child(2) {
  animation-delay: 0.1s;
}

.ai-spinner span:nth-child(3) {
  animation-delay: 0.2s;
}

.ai-spinner span:nth-child(4) {
  animation-delay: 0.3s;
}

.ai-spinner span:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>

import { Loader } from "@googlemaps/js-api-loader";

let googleMap = {};

export default async ({ app }) => {
  console.debug(
    "%cboot/google-map.js",
    "background: black; font-weight: bold; color: white;",
  );

  googleMap = new Loader({
    apiKey: process.env.GOOGLE_MAP_PUBLIC_KEY,
    version: "weekly",
  });

  const { AdvancedMarkerElement, PinElement } = await googleMap.importLibrary("marker");

  app.config.globalProperties.$googleMap = googleMap;
  // for AdvancedMarkerElement we need to enable billing for Advanced Markers in google cloud console
  app.config.globalProperties.$mapAdvancedMarkerElement = AdvancedMarkerElement;
  app.config.globalProperties.$mapPinElement = PinElement;
};

export { googleMap };

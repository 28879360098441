// const uuidv1 = require("uuid/v1");
import { v1 as uuidv1 } from "uuid";
var base64 = require("base-64");
import { Store } from "src/router";
import { i18n } from "src/boot/i18n.js";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";

export default {
  version,
  uuidV1,
  setItem,
  getItem,
  removeItem,
  xhr,
  ID,
  log,
  loge,
  logw,
  SimplifyGraph,
  RandomWord,
  convertBase64ToBinary,
  PlayAudiosampleFromBuffer,
  PlayAudiosampleFromURL,
  SetAuthorization,
  IsEmpty,
  CopyObject,
  parseHeader,
  createHeader,
  tstAnnotations,
  levelToCEFR,
  validateEmail,
  formatDateIntoString,
  formatDateTimeIntoString,
  formatMoney,
  intersectArrays,
  isValidHttpUrl,
  getUrlParameter,
  updateUrlParameter,
  isValidUuid,
  FormatDateInStr,
  FormatDateFromStr,
  GetNanoSecTime,
  ShuffleArray,
  FindCurrentLocalISO3,
  IsPositiveInteger,
  capitalize,
  getDatePatternByLocale,
  formatDateByLocale,
  formatDateTimeByLocale,
  formatTimeByLocale,
  utf8ToBase64,
  base64ToUtf8,
  transcode,
  translateLanguage,
  reduceString,
  isNonEmptyArray,
  secondsToHMS,
  switchObjectsByID,
  calculateEmotionPoint,
  safeJsonParse,
};

function version() {
  if (getItem("VOC_USER_TOKEN_SCOPE")) {
    removeItem("VOC_USER_TOKEN_SCOPE");
  }
  const v = process.env.WUI_GIT_HASH;
  setItem("VOC_VERSION_WUI_GIT_COMMIT", v);

  xhr("get", "application/json", "version", null, function (response) {
    const r = JSON.parse(response);

    setItem("VOC_VERSION_API_GIT_COMMIT", r.data?.APIGitCommit);
    setItem("VOC_VERSION_API_BUILT_TIME", r.data?.APIBuiltTime);
    setItem("VOC_VERSION_WRK_GIT_COMMIT", r.data?.WRKGitCommit);
    setItem("VOC_VERSION_WRK_BUILT_TIME", r.data?.WRKBuiltTime);
    setItem("VOC_VERSION_DSP_GIT_COMMIT", r.data?.DSPGitCommit);
    setItem("VOC_VERSION_DSP_BUILT_TIME", r.data?.DSPBuiltTime);
    // console.table({ ...r.data, WUIGitCommit: v });
  });
}

function uuidV1() {
  return uuidv1();
}

function setItem(name, value, minutes) {
  let expires = undefined;

  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = date;
  }

  localStoragePolyFill.setItem(
    name,
    JSON.stringify({ value: value, expires: expires }),
  );
}

function getItem(name) {
  const item = JSON.parse(localStoragePolyFill.getItem(name));

  if (item?.expires > new Date()) {
    localStoragePolyFill.removeItem(name);
    return undefined;
  }

  return item?.value;
}

function removeItem(name) {
  localStoragePolyFill.removeItem(name);
}

function xhr(verb, contentType, url, data, callback) {
  var request = new XMLHttpRequest();

  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      callback(request.responseText);
    }
  };

  request.open(verb, process.env.PUBLIC_API_URL + "/api/v1/" + url);

  if (!getItem("VOC_USER_TOKEN")) {
    request.setRequestHeader(
      "Authorization",
      "Anonymous fc2d8497-8207-46c9-afba-e60bea29ee00",
    );
  } else {
    request.setRequestHeader(
      "Authorization",
      "Bearer " + getItem("VOC_USER_TOKEN"),
    );
  }

  if (contentType !== "") {
    request.setRequestHeader("Content-Type", contentType);
  }

  request.send(data);
}

// Audio XHRs

function ID() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "vocID" + Math.random().toString(36).substr(2, 9);
}

function log() {
  var args = Array.prototype.slice.call(arguments);
  args.unshift("{VOC}\t");
  console.log.apply(console, args);
}

function loge() {
  var args = Array.prototype.slice.call(arguments);
  args.unshift("{VOC}\t");
  console.error.apply(console, args);
}

function logw() {
  var args = Array.prototype.slice.call(arguments);
  args.unshift("{VOC}\t");
  console.warn.apply(console, args);
}

// SimplifyGraph removes extra information from the GraphObject.
function SimplifyGraph(graph) {
  if (!graph) {
    return null;
  }

  console.log("SimplifyGraph  in", graph);

  const l = graph.links;
  const r = [];

  console.log("SimplifyGraph  l", l);

  l.forEach(function (el) {
    const obj = {
      sourceid: el.source.id,
      targetid: el.target.id,
    };
    r.push(obj);
    console.log("SimplifyGraph obj", obj);
  });

  console.log("SimplifyGraph ", r);

  graph.links = r;

  return JSON.stringify(graph);
}

function RandomWord() {
  var wordsDeterminers = [
    "Afraid",
    "Alike",
    "Hard",
    "Long",
    "Only",
    "Even",
    "Eventually",
    "Hardly",
    "Hopefully",
    "Largely",
    "Likely",
    "Surely",
    "Too",
    "Ultimately",
    "About",
    "Ago",
    "Already",
    "Always",
    "Early",
    "Ever",
    "Hardlyever",
    "Rarely",
    "Scarcely",
    "Seldom",
    "Next",
    "Nolonger",
    "Longer",
    "Nomore",
    "Not",
    "Now",
    "Often",
    "Once",
    "Soon",
    "Still",
    "Then",
    "Usually",
  ];
  var wordsPrepositions = [
    "of",
    "near",
    "like",
    "about",
    "across",
    "after",
    "isnot",
    "around",
    "atop",
    "athwart",
    "behind",
    "below",
    "beyond",
    "via",
    "versus",
  ];
  var words = [
    "cell",
    "biome",
    "nucleus",
    "mitochondria",
    "ketones",
    "metabolic",
    "fasting",
    "brain",
    "BDNF",
    "ghrelin",
    "insulin",
    "leptin",
    "test",
    "GKI",
    "neurons",
    "axons",
    "cortex",
    "gyrus",
    "Broca's Area",
    "Wernicke's Area",
    "cerebellum",
    "hippocampus",
    "hypothalamus",
    "thalamus",
    "glucose",
  ];

  return (
    wordsDeterminers[Math.ceil(Math.random() * wordsDeterminers.length)] +
    " " +
    words[Math.ceil(Math.random() * words.length)] +
    " " +
    wordsPrepositions[Math.ceil(Math.random() * wordsPrepositions.length)] +
    " " +
    words[Math.ceil(Math.random() * words.length)]
  );
}

// convertBase64ToBinary if we're getting as base64 encoded array from backend API
function convertBase64ToBinary(dataURI) {
  var BASE64_MARKER = ";base64,";
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
}

function utf8ToBase64(str) {
  let encoder = new TextEncoder();
  let data = encoder.encode(str);
  let base64 = btoa(String.fromCharCode(...new Uint8Array(data)));
  return base64;
}

// we can't use "base-64" js-script because utf8
// if page in utf-8, base-64 script will be decode twice (get not relevant symbols for RUS language for example)
function base64ToUtf8(base64) {
  // it's work, but escape is depricated
  // let tmp = decodeURIComponent(escape(atob(base64)));

  let data = atob(base64);
  let bytes = new Uint8Array(data.length);
  for (let i = 0; i < data.length; i++) {
    bytes[i] = data.charCodeAt(i);
  }
  let decoder = new TextDecoder();
  let str = decoder.decode(bytes);
  return str;
}

function SetAuthorization() {
  let authorizationHeader = "";
  if (!getItem("VOC_USER_TOKEN")) {
    authorizationHeader = "Anonymous fc2d8497-8207-46c9-afba-e60bea29ee00";
  } else {
    authorizationHeader = "Bearer " + getItem("VOC_USER_TOKEN");
  }
  return authorizationHeader;
}

function PlayAudiosampleFromURL(url, volume = 0.01) {
  // 1 % volume
  var request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.responseType = "arraybuffer";
  request.onload = function () {
    var audioData = request.response;
    PlayAudiosampleFromBuffer(audioData, volume);
  };
  request.send();
}

function PlayAudiosampleFromBuffer(data, volume) {
  // NOTE: https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/decodeAudioData
  var audioContext = new (window.AudioContext || window.webkitAudioContext)();
  var source = audioContext.createBufferSource();
  audioContext.decodeAudioData(
    data,
    function (buffer) {
      source.buffer = buffer;

      var gainNode = audioContext.createGain();
      gainNode.gain.value = volume;
      gainNode.connect(audioContext.destination);

      // now instead of connecting to aCtx.destination, connect to the gainNode
      source.connect(gainNode);
      // source.connect(audioContext.destination)

      source.loop = false;
      source.start(0);
    },
    function (e) {
      console.error("error-17410cb2", "with decoding audio data", e);
    },
  );
}

function IsEmpty(val) {
  return (
    isNaN(val) || val === null // NaN, undefined, {}
  );
}

function CopyObject(fromObject, toObject) {
  let i;
  const toStr = Object.prototype.toString;
  const astr = "[object Array]";
  const nstr = "[object Null]";
  toObject = toObject || {};
  for (i in fromObject) {
    // eslint-disable-next-line
    if (fromObject.hasOwnProperty(i)) {
      if (typeof fromObject[i] === "object") {
        // console.log(toStr.call(fromObject[i]));
        // toObject[i] = (toStr.call(fromObject[i]) === astr) ? [] : {};
        if (toStr.call(fromObject[i]) === astr) {
          toObject[i] = [];
        } else {
          if (toStr.call(fromObject[i]) === nstr) {
            // null
            toObject[i] = null;
          } else {
            toObject[i] = {};
          }
        }
        this.CopyObject(fromObject[i], toObject[i]);
      } else {
        toObject[i] = fromObject[i];
      }
    }
  }
  return toObject;
}

function readText(buffer, start, length) {
  var a = new Uint8Array(buffer, start, length);
  var str = "";
  for (var i = 0; i < a.length; i++) {
    str += String.fromCharCode(a[i]);
  }
  return str;
}

function readDecimal(buffer, start, length) {
  var a = new Uint8Array(buffer, start, length);
  return fromLittleEndianDecBytes(a);
}

function fromLittleEndianDecBytes(a) {
  var sum = 0;
  for (var i = 0; i < a.length; i++) sum |= a[i] << (i * 8);
  return sum;
}

function parseHeader(buffer) {
  var wavHeader = {
    chunkID: null,
    chunkSize: null,
    format: null,
    audioFormat: null,
    numChannels: null,
    sampleRate: null,
    byteRate: null,
    blockAlign: null,
    bitsPerSample: null,
    subchunk2ID: null,
    subchunk2Size: null,
    subchunk2Offset: null,
  };
  wavHeader.chunkID = readText(buffer, 0, 4);
  wavHeader.chunkSize = readDecimal(buffer, 4, 4);
  wavHeader.format = readText(buffer, 8, 4);
  wavHeader.audioFormat = readDecimal(buffer, 20, 2);
  wavHeader.numChannels = readDecimal(buffer, 22, 2);
  wavHeader.sampleRate = readDecimal(buffer, 24, 4);
  wavHeader.byteRate = readDecimal(buffer, 28, 4); // ByteRate == SampleRate * NumChannels * BitsPerSample/8
  wavHeader.blockAlign = readDecimal(buffer, 32, 2); // BlockAlign == NumChannels * BitsPerSample/8
  wavHeader.bitsPerSample = readDecimal(buffer, 34, 2);
  wavHeader.subchunk2ID = readText(buffer, 36, 4);
  wavHeader.subchunk2Size = readDecimal(buffer, 40, 4);
  if (
    wavHeader.chunkID !== "RIFF" ||
    wavHeader.format !== "WAVE" ||
    wavHeader.numChannels >= 2
  ) {
    throw "Incorrect file format";
  }
  // if (wavHeader.sampleRate !== 48000) {
  //   throw 'Please upload 48000Hz .wav file';
  // };
  if (wavHeader.bitsPerSample !== 16) {
    throw "Please upload 16 bit .wav file";
  }
  if (wavHeader.subchunk2ID == "data") {
    if (wavHeader.chunkSize - wavHeader.subchunk2Size !== 36) {
      throw "File is corrupted";
    }
  } else {
    var subchunkData = "";
    var a = new Uint8Array(buffer, 44, wavHeader.subchunk2Size + 8);
    for (var i = 1; i < a.length; i++) {
      subchunkData += String.fromCharCode(a[i]);
      if (subchunkData.includes("data")) {
        wavHeader.subchunk2Offset = i;
        break;
      }
    }
  }
  return wavHeader;
}

function createHeader(header, offsetSize) {
  var numChannels = header.numChannels || 1;
  var sampleRate = header.sampleRate || 48000;
  var bytesPerSample = header.bytesPerSample || 2;
  var blockAlign = header.numChannels * bytesPerSample;
  var byteRate = sampleRate * blockAlign;
  var dataSize = offsetSize;
  var buffer = new ArrayBuffer(44);
  var dv = new DataView(buffer);
  var p = 0;

  function writeString(s) {
    for (var i = 0; i < s.length; i++) {
      dv.setUint8(p + i, s.charCodeAt(i));
    }
    p += s.length;
  }

  function writeUint32(d) {
    dv.setUint32(p, d, true);
    p += 4;
  }

  function writeUint16(d) {
    dv.setUint16(p, d, true);
    p += 2;
  }

  writeString("RIFF"); // ChunkID
  writeUint32(dataSize + 36); // ChunkSize
  writeString("WAVE"); // Format
  writeString("fmt "); // Subchunk1ID
  writeUint32(16); // Subchunk1Size
  writeUint16(1); // AudioFormat
  writeUint16(numChannels); // NumChannels
  writeUint32(sampleRate); // SampleRate
  writeUint32(byteRate); // ByteRate
  writeUint16(blockAlign); // BlockAlign
  writeUint16(bytesPerSample * 8); // BitsPerSample
  writeString("data"); // Subchunk2ID
  writeUint32(dataSize); // Subchunk2Size

  return buffer;
}

// NOTE: 1a33f364
function tstAnnotations(refAnnotations, dp, compareFrames) {
  const tstAnnotations = [];

  if (refAnnotations && dp) {
    refAnnotations.forEach((refAnn) => {
      const tstAnn = {
        Transcription: refAnn.Transcription,
        Description: refAnn.Description,
        SimilarityPronunciation: 0,
        SimilarityPitch: 0,
        SimilarityEnergy: 0,
        SimilarityBreath: 0,
        SimilarityEmotion: 0,
        SimilarityTotal: 0,
      };

      const refAnnFirstSegment =
        dp.find((s) => s.SegmentEndRef > refAnn.SegmentStart) || dp[0];
      const refAnnLastSegment =
        dp.find((s) => s.SegmentEndRef > refAnn.SegmentEnd) ||
        dp[dp.length - 1];

      if (Array.isArray(compareFrames.Pronunciation)) {
        const annFrames = compareFrames.Pronunciation.filter(
          (frame) =>
            frame.SegmentEnd > refAnn.SegmentStart &&
            frame.SegmentStart < refAnn.SegmentEnd,
        );
        tstAnn.SimilarityPronunciation =
          annFrames.reduce(
            (previousValue, currentFrame) =>
              previousValue +
              currentFrame.AnMMFCC * 0.5 +
              currentFrame.AnMPLP * 0.5,
            0,
          ) / Math.max(annFrames.length, 1); // NOTE: 0f8cb4ca-backend-has-split.
      }
      if (Array.isArray(compareFrames.Pitch)) {
        const annFrames = compareFrames.Pitch.filter(
          (frame) =>
            frame.SegmentEnd > refAnn.SegmentStart &&
            frame.SegmentStart < refAnn.SegmentEnd &&
            frame.Pitch > -1,
        );
        tstAnn.SimilarityPitch =
          annFrames.reduce(
            (previousValue, currentFrame) => previousValue + currentFrame.Pitch,
            0,
          ) / Math.max(annFrames.length, 1);
      }
      if (Array.isArray(compareFrames.Energy)) {
        const annFrames = compareFrames.Energy.filter(
          (frame) =>
            frame.SegmentEnd > refAnn.SegmentStart &&
            frame.SegmentStart < refAnn.SegmentEnd &&
            frame.Energy > -1,
        );
        tstAnn.SimilarityEnergy =
          annFrames.reduce(
            (previousValue, currentFrame) =>
              previousValue + currentFrame.Energy,
            0,
          ) / Math.max(annFrames.length, 1);
      }
      if (Array.isArray(compareFrames.Breath)) {
        const annFrames = compareFrames.Breath.filter(
          (frame) =>
            frame.SegmentEnd > refAnn.SegmentStart &&
            frame.SegmentStart < refAnn.SegmentEnd &&
            frame.Breath > -1,
        );
        tstAnn.SimilarityBreath =
          annFrames.reduce(
            (previousValue, currentFrame) =>
              previousValue + currentFrame.Breath,
            0,
          ) / Math.max(annFrames.length, 1);
      }
      if (Array.isArray(compareFrames.Emotion)) {
        const annFrames = compareFrames.Emotion.filter(
          (frame) =>
            frame.SegmentEnd > refAnn.SegmentStart &&
            frame.SegmentStart < refAnn.SegmentEnd &&
            frame.Emotion > -1,
        );
        tstAnn.SimilarityEmotion =
          annFrames.reduce(
            (previousValue, currentFrame) =>
              previousValue + getEmotionSimilarity(currentFrame),
            0,
          ) / Math.max(annFrames.length, 1);
      }
      // Note: ref-5f025e84 see formula by code
      tstAnn.SimilarityTotal =
        (tstAnn.SimilarityPronunciation * 80 +
          tstAnn.SimilarityPitch * 6 +
          tstAnn.SimilarityEnergy * 2 +
          tstAnn.SimilarityBreath * 2 +
          tstAnn.SimilarityEmotion * 10) /
        100;
      tstAnn.SegmentStart = refAnnFirstSegment.SegmentStartTest;
      tstAnn.SegmentEnd = refAnnLastSegment.SegmentStartTest;
      // tstAnn.SimilarityPron = 12.4;

      tstAnnotations.push(tstAnn);
    });
  }

  return tstAnnotations;
}

// NOTE: we use CEFR scale from https://www.efset.org/english-score/
function levelToCEFR(levelValue) {
  var levelLetter;

  if (levelValue >= 71) {
    levelLetter = "C2";
  } else if (levelValue >= 61 && levelValue <= 70) {
    levelLetter = "C1";
  } else if (levelValue >= 51 && levelValue <= 60) {
    levelLetter = "B2";
  } else if (levelValue >= 41 && levelValue <= 50) {
    levelLetter = "B1";
  } else if (levelValue >= 31 && levelValue <= 40) {
    levelLetter = "A2";
  } else {
    levelLetter = "A1";
  }
  return levelLetter;
}

function validateEmail(email) {
  if (email) {
    // const re = /\S+@\S+\.\S+/ // '.' not for all emails
    const re = /([a-z]|[A-Z]|[0-9]|[-_.+]){1,}@([a-z]|[A-Z]|[0-9]|[-_.]){1,}/;
    return re.test(email);
  } else {
    return false;
  }
}

// d = 'YYYY-MM-DDT00:00:00Z' (RFC3339) => '01/02/2006',....
// d = date => '01/02/2006',...
function formatDateIntoString(d, format) {
  if (d === null || d === undefined) {
    return null;
  }
  if (format === null || format === undefined) {
    format = "01/02/2006";
  }

  const dd = new Date(d);
  let result = "";
  switch (format) {
    case "01/02/2006":
      result = dd.getMonth() + 1;
      if (dd.getMonth() + 1 < 10) {
        result = "0" + result;
      }
      result += "/";
      if (dd.getDate() < 10) {
        result += "0" + dd.getDate();
      } else {
        result += dd.getDate();
      }
      result += "/" + dd.getFullYear();
      break;
    case "Jan 2 2006":
      result = dd.toLocaleString("default", { month: "short" });
      result += " " + dd.getDate() + " " + dd.getFullYear();
  }
  return result;
}

// d = 'YYYY-MM-DDT00:00:00Z' (RFC3339) => 'MM/DD/YYYY HH:MM:SS'
// d = date => 'MM/DD/YYYY HH:MM:SS'
function formatDateTimeIntoString(d, onlydate) {
  if (d === null || d === undefined) {
    return null;
  }

  let dd = new Date(d);

  let result =
    new Intl.DateTimeFormat("en", { month: "short" }).format(dd) +
    " " +
    new Intl.DateTimeFormat("en", { day: "numeric" }).format(dd) +
    " " +
    new Intl.DateTimeFormat("en", { year: "numeric" }).format(dd);

  if (!onlydate) {
    result +=
      " " +
      new Intl.DateTimeFormat("en", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(dd);
  }
  // // 'MM/DD/YYYY'
  // let result = dd.getMonth() + 1;
  // if (dd.getMonth() + 1 < 10) {
  //   result = "0" + result;
  // }
  // result += "/";
  // if (dd.getDate() < 10) {
  //   result += "0" + dd.getDate();
  // } else {
  //   result += dd.getDate();
  // }
  // result += "/" + dd.getFullYear();

  // result += " ";
  // if (dd.getHours() < 10) {
  //   result += "0" + dd.getHours();
  // } else {
  //   result += dd.getHours();
  // }
  // result += ":";
  // if (dd.getMinutes() < 10) {
  //   result += "0" + dd.getMinutes();
  // } else {
  //   result += dd.getMinutes();
  // }
  // result += ":";
  // if (dd.getSeconds() < 10) {
  //   result += "0" + dd.getSeconds();
  // } else {
  //   result += dd.getSeconds();
  // }

  return result;
}

function getDatePatternByLocale(locale, isMask) {
  var options = { year: "numeric", month: "2-digit", day: "2-digit" };
  // formatToParts() returns array of object breaking down the locales dateformat
  // [
  //  {type: "month", value: "03"},
  //  {type: "literal", value: "/"},
  //  {type: "day", value: "30"},
  //  {type: "literal", value: "/"},
  //  {type: "year", value: "2021"},
  // ]
  var formatter = new Intl.DateTimeFormat(locale, options).formatToParts();

  // for "en" return "####/##/##"
  if (isMask) {
    return formatter
      .map(function (e) {
        switch (e.type) {
          case "month":
            return "##";
            break;
          case "day":
            return "##";
            break;
          case "year":
            return "####";
            break;
          default:
            return e.value;
        }
      })
      .join("");
  }

  // for "en" return "YYYY/MM/DD"
  return formatter
    .map(function (e) {
      switch (e.type) {
        case "month":
          return "MM";
          break;
        case "day":
          return "DD";
          break;
        case "year":
          return "YYYY";
          break;
        default:
          return e.value;
      }
    })
    .join("");
}

function formatDateByLocale(locale, dd) {
  if (locale == null) {
    locale = i18n.global.locale;
  }
  var options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Intl.DateTimeFormat(locale, options).format(dd);
}

function formatDateTimeByLocale(locale, dd) {
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  if (locale == null) {
    locale = i18n.global.locale;
  }
  return new Intl.DateTimeFormat(locale, options).format(dd);
}

function formatTimeByLocale(locale, dd) {
  var options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };
  if (locale == null) {
    locale = i18n.global.locale;
  }
  return new Intl.DateTimeFormat(locale, options).format(dd);
}

// formatMoney(1.1)-> "1.10"
// formatMoney(1.146)-> "1.14"
// formatMoney(1)-> "1"
function formatMoney(val) {
  let fractional = Math.floor((val % 1) * Math.pow(10, 2));
  if (fractional > 0) {
    if (fractional > 10) {
      return "" + Math.trunc(val) + "." + ("" + fractional).substring(0, 2);
    }
    return "" + Math.trunc(val) + "." + ("" + fractional).substring(0, 1) + "0";
  }
  return "" + val;
}

function intersectArrays(a, b) {
  return a.some((r) => b.indexOf(r) >= 0);
}

/**
 * Function to add or update a URL parameter value.
 * @param {string} url - The original URL.
 * @param {string} key - The parameter key to add or update.
 * @param {string} value - The value to set for the parameter.
 * @returns {string} - The updated URL.
 */
function updateUrlParameter(url, key, value) {
  console.log("updateUrlParameter", url, key, value);
  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);

  // Set/update/delete the parameter value
  if (value) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }

  // Update the search property of the URL object with the modified searchParams
  urlObject.search = searchParams.toString();

  // Return the updated URL
  console.log("result:", urlObject.toString());
  return urlObject.toString();
}

/**
 * Function to get the value of a URL parameter from a given URL string
 * @param {string} url - The URL string from which to retrieve the parameter value.
 * @param {string} key - The parameter key to retrieve.
 * @returns {string} - The value of the parameter or the default value if not found.
 */
function getUrlParameter(url, key) {
  console.log("getUrlParameter", url, key);
  // Create a URL object from the provided URL string
  const urlObject = new URL(url);

  // Get the URL query parameters
  const urlParams = new URLSearchParams(urlObject.search);

  // Check if the parameter exists
  if (urlParams.has(key)) {
    // Return the value of the parameter
    return urlParams.get(key);
  }

  return null;
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function isValidUuid(string) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  // String with valid UUID separated by dash
  // const str = "a24a6ea4-ce75-4665-a070-57453082c256";

  return regexExp.test(string);
}

// d = 'YYYY-MM-DDT00:00:00Z' (RFC3339) => 'MM/DD/YYYY'
// d = date => 'MM/DD/YYYY'
function FormatDateInStr(d) {
  if (d === null || d === undefined) {
    return null;
  }

  let dd = new Date(d);

  // 'MM/DD/YYYY'
  let result = dd.getMonth() + 1;
  if (dd.getMonth() + 1 < 10) {
    result = "0" + result;
  }
  result += "/";
  if (dd.getDate() < 10) {
    result += "0" + dd.getDate();
  } else {
    result += dd.getDate();
  }
  result += "/" + dd.getFullYear();
  return result;
}

// d = 'MM/DD/YYYY' => date
function FormatDateFromStr(d) {
  if (d === null || d === undefined) {
    return null;
  }
  if (d.length !== 10) {
    return null;
  }

  let re = new RegExp("[0-9]{2}/[0-9]{2}/[0-9]{4}", "i");
  if (d.search(re) !== 0) {
    return null;
  }

  let newDate = new Date(
    d.substring(6),
    parseInt(d.substring(0, 2)) - 1,
    d.substring(3, 5),
  );
  // if (Object.prototype.toString.call(newDate) === '[object Date]' && !isNaN(newDate.getTime())) {
  if (newDate.toString() === "Invalid Date") {
    return null;
  }
  return newDate;
}

function GetNanoSecTime() {
  var date = new Date();
  return date.valueOf(); //1512239400000 in milliseconds format
}

function ShuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function FindCurrentLocalISO3(langID) {
  if (langID) {
    return Store.getters.languages.find((language) => {
      return language.ID == langID;
    })?.iso_3;
  }
  return (
    Store.getters.languages.find((language) => {
      return language.locale == i18n.global.locale;
    })?.iso_3 || "eng"
  );
}

function IsPositiveInteger(n) {
  return 0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n);
}

function getDistance(x0, y0, x1, y1) {
  return Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
}

function getEmotionSimilarity(frame) {
  // zero distance = 100% similarity, distance = 2 - 0% similarity
  return (
    (1 -
      getDistance(
        frame.valenceRef,
        frame.valenceTest,
        frame.arousalRef,
        frame.arousalTest,
      ) /
        2) *
    100
  );
}

function capitalize(str) {
  return str.charAt(0)?.toUpperCase() + str.slice(1);
}

/**
 * Singleton FFmpeg instance with proper loading handling.
 */
const ffmpegInstance = (() => {


  // ffmpeg-wasm 0.12.* single-threaded
  // To update ffmpeg, download ffmpeg-core.js and ffmpeg-core.wasm from
  // baseURL = 'https://unpkg.com/@ffmpeg/core@latest/dist/umd'
  // for now, latest is 0.12.6

  let instance = null;
  let loadPromise = null;

  return function getInstance(jsBlobURL, wasmBlobURL) {
    if (instance) return loadPromise; // Always return the promise to ensure it's loaded

    instance = new FFmpeg();
    if (process.env.PUBLIC_API_URL !== "https://app.voccent.com") {
      instance.on("log", (msg) => {
        console.log(msg);
      });
      instance.on("progress", (msg) => {
        console.log(msg);
      });
    }
    loadPromise = Promise.all([
      jsBlobURL || toBlobURL("ffmpeg-core.js", "text/javascript"),
      wasmBlobURL || toBlobURL("ffmpeg-core.wasm", "application/wasm"),
    ])
      .then(([coreURL, wasmURL]) => instance.load({ coreURL, wasmURL }))
      .then(() => instance);

    return loadPromise;
  };
})();

/**
 * Converts a media file to the desired format using ffmpeg-wasm.
 *
 * @param {string | Blob | File} srcURL  - Input file URL or Blob.
 * @param {string} srcFilename - Input filename.
 * @param {string} type        - 'ogg' | 'wav' | 'mp3' | 'wavNormalize'.
 * @param {Map} [loadingMap]   - Optional map to track loading state.
 * @param {boolean} [download=false] - If true, downloads the converted file.
 * @returns {Promise<Uint8Array>} - The converted file as Uint8Array.
 */
function transcode(srcURL, srcFilename, type, loadingMap, download = false) {
  return ffmpegInstance().then((ffmpeg) => {
    const key = `${srcFilename}_${type}`;
    if (loadingMap) loadingMap.set(key, true);

    let dstFilename = srcFilename.replace(/\..+$/, "") + "." + type;
    if (dstFilename === srcFilename) dstFilename = "_" + dstFilename;

    return fetchFile(srcURL)
      .then((fileData) => ffmpeg.writeFile(srcFilename, fileData))
      .then(() => {
        let ffmpegParams;
        switch (type) {
          case "ogg":
            ffmpegParams = ["-c:a", "libopus", "-ac", "1", "-ar", "48000", "-vn"];
            break;
          case "wav":
            ffmpegParams = [
              "-acodec", "pcm_s16le",
              "-ac", "1",
              "-ar", "48000",
              "-vn", "-bitexact", "-map_metadata", "-1"
            ];
            break;
          case "mp3":
            ffmpegParams = ["-b:a", "192k", "-ac", "1", "-ar", "48000", "-vn"];
            break;
          case "wavNormalize":
            ffmpegParams = ["-af", "loudnorm=I=-16:LRA=11:TP=-1.5", "-c:a", "pcm_s16le"];
            type = "wav";
            dstFilename = srcFilename.replace(/\..+$/, "") + "." + type;
            if (dstFilename === srcFilename) dstFilename = "_" + dstFilename;
            break;
          default:
            throw new Error(`Unknown format: ${type}`);
        }
        return ffmpeg.exec(["-i", srcFilename, ...ffmpegParams, dstFilename]);
      })
      .then(() => ffmpeg.readFile(dstFilename))
      .then((result) => {
        if (download) {
          const resBlobURL = URL.createObjectURL(new Blob([result.buffer], { type: "audio/" + type }));
          const anchor = document.createElement("a");
          anchor.href = resBlobURL;
          anchor.download = dstFilename;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          setTimeout(() => URL.revokeObjectURL(resBlobURL), 10000);
        }
        return result;
      })
      .catch((error) => {
        console.error(`Error transcoding ${srcFilename}:`, error);
        throw error;
      })
      .finally(() => {
        if (loadingMap) loadingMap.delete(key);
      });
  });
}

/**
 * Returns translated language name.
 *
 * @param {string} langIdOrName
 * @returns {string}
 */
export function translateLanguage(langIdOrName) {
  return (
    Store.getters.languages.find((language) => {
      return language.ID === langIdOrName;
    })?.localName ||
    Store.getters.languages.find((language) => {
      return language.name.toLowerCase() === langIdOrName.toLowerCase();
    })?.localName ||
    langIdOrName
  );
}

/**
 * Trim, remove non-print characters, convert multi-spaces to single-spaces
 *
 * @param {string} str
 * @returns {string}
 */
export function reduceString(str) {
  if (!(typeof str === "string" || str instanceof String)) return str;
  let s = str
    .replace(/[\x00-\x09\x0B-\x0C\x0E-\x1F\x7F-\x9F\s\xA0]/g, " ")
    .replaceAll("  ", " ")
    .trim();
  while (s.includes("  ")) {
    s = s.replaceAll("  ", " ");
  }
  return s;
}

/**
 * Check if variable is non-empty array with optional minimum length.
 *
 * @param {array} arr
 * @param {number} minLength
 * @returns {boolean}
 */
export function isNonEmptyArray(arr, minLength = 1) {
  return Array.isArray(arr) && arr.length >= minLength;
}

/**
 * Convert seconds to HH:MM:SS format.
 *
 * @param {number} seconds
 * @returns {string}
 */
export function secondsToHMS(seconds) {
  // check if seconds is a number
  if (isNaN(seconds) || !seconds) {
    return "00:00:00";
  }
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
}

/**
 * Switch objects in array by ID in-place
 *
 * @param {array} array - array of objects
 * @param {string} idFieldName - field name of ID
 * @param {string} id1 - ID of the first object
 * @param {string} id2 - ID of the second object
 *
 */
export function switchObjectsByID(array, idFieldName, id1, id2) {
  if (
    !Array.isArray(array) ||
    !array.length ||
    !idFieldName ||
    !id1 ||
    !id2 ||
    id1 === id2
  ) {
    console.error("switchObjectsByID: invalid arguments");
    return;
  }
  if (!array[0].hasOwnProperty(idFieldName)) {
    console.error("switchObjectsByID: idFieldName not found in array objects");
    return;
  }
  const index1 = array.findIndex((obj) => obj[idFieldName] === id1);
  const index2 = array.findIndex((obj) => obj[idFieldName] === id2);
  if (index1 !== -1 && index2 !== -1)
    [array[index1], array[index2]] = [array[index2], array[index1]];
}

//
// ChatGPT written
//

// NOTE: These functions are commented, because it's simpler to do the same on
// the backend, as we need to save data to the DB anyways in the Joined format.

// function joinFpEl(arr) {
//   // NOTE: 12b8baf8
//   const total = arr.flatMap((element) => element.total);
//   const frames = arr.flatMap((element) => element.frames);
//   const dp = arr.flatMap((element) => element.dp);

//   const totalArray = [...total, ...total];
//   const framesArray = [...frames, ...frames];
//   const dpArray = [...dp, ...dp];

//   const groupedTotalArray = groupFpByMetric(totalArray);
//   return { total: totalArray, frames: framesArray, dp: dpArray };
// }

// function groupFpByMetric(arrayMetric) {
//   // NOTE: 389257b7
//   return arrayMetric
//     .reduce((groups, item) => {
//       const group = groups.find((g) => g.Metric === item.Metric);
//       if (group) {
//         group.ValueMeanSum += item.ValueMean;
//         group.ValueStdSum += item.ValueStd;
//         group.Count++;
//       } else {
//         groups.push({
//           Metric: item.Metric,
//           ValueMeanSum: item.ValueMean,
//           ValueStdSum: item.ValueStd,
//           Count: 1,
//         });
//       }
//       return groups;
//     }, [])
//     .map((group) => ({
//       Metric: group.Metric,
//       ValueMean: group.ValueMeanSum / group.Count,
//       ValueStd: group.ValueStdSum / group.Count,
//     }));
// }

/**
 * @typedef {Object} ArousalValencePoint
 * @property {number} Arousal
 * @property {number} Valence
 */

/**
 * Calculate emotion point based on arousal and valence values.
 * @param {ArousalValencePoint} obj
 * @returns {{value, color: string, tooltip: string}}
 */
export function calculateEmotionPoint(obj) {
  const defaultColors = [
    [255, 255, 0], // [255, 215, 0], // gold
    [255, 0, 0], // [255, 99, 71], // tomato
    [139, 69, 19], //  SaddleBrown
    [0, 0, 255], // [70, 130, 180], // SteelBlue
    [250, 235, 215], // AntiqueWhite
  ];
  const defaultLabels = [
    "happy",
    "aggressive",
    "sluggish",
    "relaxing",
    "neutral",
  ];

  const makeGradient = (startColor, endColor, percentFade) => {
    let diffRed = endColor[0] - startColor[0];
    let diffGreen = endColor[1] - startColor[1];
    let diffBlue = endColor[2] - startColor[2];

    diffRed = diffRed * percentFade + startColor[0];
    diffGreen = diffGreen * percentFade + startColor[1];
    diffBlue = diffBlue * percentFade + startColor[2];
    return [diffRed, diffGreen, diffBlue];
  };

  let pointer = {
    arousal: obj.Arousal,
    valence: obj.Valence,
  };
  // is it actual? if no any data
  if (pointer.arousal == -1) {
    pointer.arousal = 0;
  }
  if (pointer.valence == -1) {
    pointer.valence = 0;
  }
  let numMainColor = 0;
  if (pointer.arousal >= 0) {
    if (pointer.valence >= 0) {
      numMainColor = 0;
    } else {
      numMainColor = 1;
    }
  } else {
    if (pointer.valence >= 0) {
      numMainColor = 3;
    } else {
      numMainColor = 2;
    }
  }
  let lng = Math.sqrt(
    pointer.arousal * pointer.arousal + pointer.valence * pointer.valence,
  );
  let clr = makeGradient(defaultColors[4], defaultColors[numMainColor], lng);

  let numNearbyColor = numMainColor; // color number ~ number quadrant
  if (Math.abs(pointer.arousal) > Math.abs(pointer.valence)) {
    switch (numMainColor) {
      case 0:
      case 2:
        numNearbyColor++;
        break;
      case 1:
      case 3:
        numNearbyColor--;
        break;
    }
  } else {
    switch (numMainColor) {
      case 0:
        numNearbyColor = 3;
        break;
      case 1:
        numNearbyColor++;
        break;
      case 2:
        numNearbyColor--;
        break;
      case 3:
        numNearbyColor = 0;
        break;
    }
  }

  let clrNearby_ = clr;

  // if (data.valence != -1) {
  // angle between current pointer and x || y (nearest) + 45 degree (second color from other quadrant)
  let dG = Math.asin(Math.abs(pointer.arousal) / lng);
  if (dG > (45 * Math.PI) / 180) {
    dG = (90 * Math.PI) / 180 - dG;
  }
  dG += (45 * Math.PI) / 180;

  // dl - length arc between current point and point nearest other color
  let dl = lng * dG;
  // dlK - length arc between 2 main colors between 2 quadrant (constant)
  let dlK = (90 / 360) * 2 * Math.PI * lng;

  let clrNearby = makeGradient(
    defaultColors[4],
    defaultColors[numNearbyColor],
    lng,
  );
  clrNearby_ = makeGradient(clrNearby, clr, Math.abs(dl / dlK));
  // }

  // console.log(
  //   "points emotion: ",
  //   pointer.arousal,
  //   pointer.valence,
  //   clrNearby_,
  // );
  return {
    value: pointer.arousal,
    color: "rgb(" + clrNearby_.join(",") + ")",
    tooltip:
      pointer.arousal === 0 && pointer.valence === 0
        ? "neutral"
        : defaultLabels[numMainColor],
  };
}
/**
 * Safe JSON parse
 * @param {string} str
 */
function safeJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

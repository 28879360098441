<template>
  <div
    class="justify-center items-center"
    style="position: relative; overflow: hidden"
    :style="{
      height: view.height,
      width: view.width,
    }"
  >
    <canvas
      :id="data.uid + '_emotion_analysis'"
      style="z-index: 100000"
      :width="view.width"
      :height="view.height"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { getCssVar } from "quasar";
import h from 'src/h/helpers.js';
import { shallowRef } from "vue";

export default {
  props: {
    data: {
      // {uid, data}
      type: Object,
      default: function () {
        return {};
      },
    },
    view: {
      // {height, width}
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    this.activityChart = null;
    this.values = shallowRef([]);
    this.colors = shallowRef([]);
    this.labelsX = shallowRef([]);
    this.tooltips = shallowRef([]);
    return {
      // values: [],
      // colors: [],
      // labelsX: [],
      // tooltips: [],
      defaultColors: [
        [255, 255, 0], // [255, 215, 0], // gold
        [255, 0, 0], // [255, 99, 71], // tomato
        [139, 69, 19], //  SaddleBrown
        [0, 0, 255], // [70, 130, 180], // SteelBlue
        [250, 235, 215], // AntiqueWhite
      ],
      defaultLabels: ["happy", "aggressive", "sluggish", "relaxing", "neutral"],
      // currentNum: 0, // this number actual
      // activityChart: null,
    };
  },
  // emits: [],
  mounted() {
    let data = this.data.list.data;
    for (let i = 0; i < data.length; i++) {
      let pnt = h.calculateEmotionPoint(data[i]);
      // values.push(pnt.value);
      // colors.push(pnt.color);
      // tooltips.push(pnt.tooltip);
      // labelsX.push("");
      this.values.value.push(pnt.value);
      this.colors.value.push(pnt.color);
      this.tooltips.value.push(pnt.tooltip);
      this.labelsX.value.push("");
    }
    this.draw();
  },
  methods: {
    addOrRemovePoint(add, currentNum) {
      let addpnt = h.calculateEmotionPoint(add);
      let chr = this.activityChart.value;
      chr.data.datasets.forEach((dataset) => {
        if (currentNum === this.values.value.length - 1) {
          dataset.backgroundColor.splice(0, 1);
          dataset.backgroundColor.push(addpnt.color);
          dataset.data.splice(0, 1);
          dataset.data.push(addpnt.value);
          this.tooltips.value.splice(0, 1);
          this.tooltips.value.push(addpnt.tooltip);
        } else {
          dataset.backgroundColor.splice(currentNum + 1, 1, addpnt.color);
          dataset.data.splice(currentNum + 1, 1, addpnt.value);
          this.tooltips.value.splice(currentNum + 1, 1, addpnt.tooltip);
          // pnt.currentNum++;
        }
      });
      chr.update();
    },
    draw() {
      let pnt = this;
      this.activityChart = shallowRef(
        new Chart(
          document
            .getElementById(this.data.uid + "_emotion_analysis")
            .getContext("2d"),
          {
            type: "bar",
            data: {
              labels: this.labelsX.value, //this.labelsX,
              datasets: [
                {
                  label: "Emoji",
                  data: this.values.value, // this.values,
                  backgroundColor: this.colors.value, // this.colors,
                  // pointRadius: 1,
                  // pointStyle: "rect",
                  // borderWidth: 10,
                },
              ],
            },
            options: {
              categoryPercentage: 1,
              barPercentage: 0.98,
              scales: {
                y: {
                  min: -1.1,
                  max: 1.1,
                  // reverse: true, // don't work
                  grid: {
                    color: function (context) {
                      if (context.tick.value == 0) {
                        return getCssVar("e");
                      }
                      return false;
                    },
                  },
                  ticks: {
                    display: false,
                    // callback: function (val, index) {
                    //   if (val === 1 || val === -1) {
                    //     return val === 1 ? "Activated" : "Deactivated";
                    //   }
                    //   return "";
                    // },
                  },
                },
                x: {
                  border: {
                    display: false,
                  },
                  grid: {
                    color: function (context) {
                      if (context.index == 0) {
                        return getCssVar("e");
                      }
                      return false;
                    },
                  },
                  ticks: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      // let label = context.dataset.label || "";
                      // if (label) {
                      //   label += ": ";
                      // }
                      let label = "";
                      label += pnt.tooltips.value[context.parsed.x];
                      // label +=
                      //   context.parsed.y <= pnt.emojisInterval[2]
                      //     ? "Good"
                      //     : "Excellent";
                      return label;
                    },
                  },
                },
              },
              // onHover: (event, chartElement) => {
              //   event.native.target.style.cursor = chartElement[0]
              //     ? "pointer"
              //     : "default";
              // },
            },
          },
        ),
      );
    },
  },
};
</script>
<style scoped>
.blurry {
  filter: blur(30px);
}
</style>

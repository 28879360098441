<template>
  <div :class="myclass">
    <!-- The QR code will be appended here -->
    <div :class="imgclass" ref="QRCodeContainer"></div>
    <!-- Render download button if enabled -->
    <button
      v-if="download"
      :class="downloadButton"
      @click="downloadQRCode"
    >
      Download
    </button>
  </div>
</template>

<script>
import QRCodeStyling from "qr-code-styling";

export default {
  name: "QRCode",
  props: {
    // The data to encode in the QR code
    value: {
      type: String,
      required: true,
    },
    // Dimensions for the QR code
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    // Optional image to embed in the QR code
    image: {
      type: String,
      default: "",
    },
    // Options for the QR code's background
    backgroundOptions: {
      type: Object,
      default: () => ({ color: "#ffffff" }),
    },
    // Options for the QR code's corner dots
    cornersDotOptions: {
      type: Object,
      default: () => ({ type: "dot", color: "#000000" }),
    },
    // Options for the QR code's corner squares
    cornersSquareOptions: {
      type: Object,
      default: () => ({ type: "extra-rounded", color: "#000000" }),
    },
    // Options for the QR code's dots
    dotsOptions: {
      type: Object,
      default: () => ({
        type: "extra-rounded",
        color: "#000000",
        gradient: {
          type: "linear",
          rotation: 0,
          colorStops: [
            { offset: 0, color: "#000000" },
            { offset: 1, color: "#000000" },
          ],
        },
      }),
    },
    // Flag to show/hide the download button
    download: {
      type: Boolean,
      default: false,
    },
    // Options for downloading the QR code
    downloadOptions: {
      type: Object,
      default: () => ({ name: "qr_code", extension: "png" }),
    },
    // Options for the embedded image
    imageOptions: {
      type: Object,
      default: () => ({
        hideBackgroundDots: true,
        imageSize: 0.4,
        margin: 0,
      }),
    },
    // QR code generation options
    qrOptions: {
      type: Object,
      default: () => ({
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "H",
      }),
    },
    // Additional CSS classes
    downloadButton: {
      type: String,
      default: "",
    },
    fileExt: {
      type: String,
      default: "png",
    },
    imgclass: {
      type: String,
      default: "",
    },
    myclass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      qrCode: null,
    };
  },
  mounted() {
    this.initQRCode();
  },
  watch: {
    // When the QR data or options change, update the QR code
    value() {
      this.updateQRCode();
    },
    // Watch deeply for changes in objects like dotsOptions if needed
    dotsOptions: {
      handler() {
        this.updateQRCode();
      },
      deep: true,
    },
    // You can add more watchers for other props if they change dynamically.
  },
  methods: {
    initQRCode() {
      this.qrCode = new QRCodeStyling({
        width: this.width,
        height: this.height,
        type: this.fileExt,
        data: this.value,
        image: this.image,
        backgroundOptions: this.backgroundOptions,
        cornersDotOptions: this.cornersDotOptions,
        cornersSquareOptions: this.cornersSquareOptions,
        dotsOptions: this.dotsOptions,
        imageOptions: this.imageOptions,
        qrOptions: this.qrOptions,
      });
      this.qrCode.append(this.$refs.QRCodeContainer);
    },
    updateQRCode() {
      if (this.qrCode) {
        this.qrCode.update({
          data: this.value,
          backgroundOptions: this.backgroundOptions,
          cornersDotOptions: this.cornersDotOptions,
          cornersSquareOptions: this.cornersSquareOptions,
          dotsOptions: this.dotsOptions,
          imageOptions: this.imageOptions,
          qrOptions: this.qrOptions,
        });
      }
    },
    downloadQRCode() {
      if (this.qrCode) {
        this.qrCode.download(this.downloadOptions);
      }
    },
  },
};
</script>

<style>

</style>
<template>
  <q-layout class="bg-0" view="hHh LpR fFr">
    <q-page-container class="row wrap">
      <q-page class="row col q-ma-none">
        <router-view v-slot="{ Component }">
          <transition name="zoom" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
